<template>
  <div
    v-if="page"
    id="add-view"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <Toggle
      v-if="canShowRecordViews"
      toggle-trigger-classes="pt-0 pb-3"
    >
      <template #title>
        <span class="text-base font-semibold text-default">
          Record Views
        </span>
      </template>
      <template #content>
        <div :class="viewsListClasses">
          <div
            data-feature="form_view"
            data-feature-x-offset="-26"
            data-feature-y-offset="-26"
            data-view="form"
            data-quantity="many"
            class="add-view-type"
            :class="viewTypeClasses"
            @click="debouncedOnClick('form')"
          >
            <ViewIcon type="form" />
            <div>Form</div>
          </div>
          <div
            v-if="canShowDetails"
            data-view="details"
            data-quantity="one"
            class="add-view-type"
            :class="viewTypeClasses"
            @click="debouncedOnClick('details', 'one')"
          >
            <ViewIcon type="details" />
            <div>Details</div>
          </div>
          <template v-if="canShowManyViews">
            <div
              data-view="table"
              data-feature="grid_view"
              data-feature-x-offset="-26"
              data-feature-y-offset="-26"
              data-quantity="many"
              class="add-view-type"
              :class="viewTypeClasses"
              @click="debouncedOnClick('table')"
            >
              <ViewIcon type="table" />
              <div>Grid</div>
            </div>
            <div
              data-view="search"
              data-quantity="many"
              class="add-view-type"
              :class="viewTypeClasses"
              @click="debouncedOnClick('search')"
            >
              <ViewIcon type="search" />
              <div>Search</div>
            </div>
            <div
              data-view="list"
              data-quantity="many"
              class="add-view-type"
              :class="viewTypeClasses"
              @click="debouncedOnClick('list')"
            >
              <ViewIcon type="list" />
              <div>List</div>
            </div>
            <IfPlan
              :level-is-minimum-of="2"
              :allow-if-beta="true"
            >
              <div
                v-if="canShowMaps"
                data-view="map"
                data-quantity="many"
                class="add-view-type"
                :class="viewTypeClasses"
                @click="debouncedOnClick('map')"
              >
                <ViewIcon type="map" />
                <div>Map</div>
              </div>
            </IfPlan>
            <div
              v-if="canShowCalendars"
              data-view="calendar"
              data-quantity="many"
              class="add-view-type"
              :class="viewTypeClasses"
              @click="debouncedOnClick('calendar')"
            >
              <ViewIcon type="calendar" />
              <div>Calendar</div>
            </div>
            <IfPlan
              :is-starter="true"
            >
              <div
                v-if="canOptInToMaps"
                :class="viewTypeInactiveClasses"
              >
                <div>
                  <Icon
                    type="view-map"
                    class="h-[48px] w-[48px] text-[#a9a2a8]"
                  />
                  <div>Map</div>
                </div>

                <div class="flex flex-col gap-2 w-[94px]">
                  <div class="text-left text-xs ml-1">
                    Maps available with Pro plans and above
                  </div>
                  <div>
                    <UpgradeButton />
                  </div>
                </div>
              </div>
            </IfPlan>
          </template>
        </div>
      </template>
    </Toggle>

    <Toggle
      toggle-trigger-classes="pt-4 pb-3"
      :is-open="true"
    >
      <template #title>
        <span class="text-base font-semibold text-default">
          Static Views
        </span>
      </template>
      <template #content>
        <div :class="viewsListClasses">
          <div
            data-view="menu"
            class="add-view-type"
            :class="viewTypeClasses"
            @click="debouncedOnClick('menu')"
          >
            <ViewIcon type="menu" />
            <div>Menu</div>
          </div>
          <div
            data-view="rich_text"
            class="add-view-type"
            :class="viewTypeClasses"
            @click="debouncedOnClick('rich_text')"
          >
            <ViewIcon type="rich_text" />
            <div>Rich Text</div>
          </div>
          <!--
          <div data-view="image" class="add-view-type" @click="debouncedOnClick('image')">
            <ViewIcon type="image" />
            <div>Image</div>
          </div>
          <div data-view="divider" class="add-view-type" @click="debouncedOnClick('divider')">
            <ViewIcon type="divider" />
            <div>Divider</div>
          </div>
          -->
        </div>
      </template>
    </Toggle>

    <Toggle
      v-if="canShowReports"
      toggle-trigger-classes="pt-4 pb-3"
      :is-open="true"
    >
      <template #title>
        <span class="text-base font-semibold text-default">
          Reports
        </span>
      </template>
      <template #content>
        <div :class="[viewsListClasses, { 'border-0': !(canShowEcommerce || canShowCustomer) }]">
          <div
            data-view="report"
            data-report="bar"
            data-quantity="many"
            class="add-view-type"
            :class="viewTypeClasses"
            @click="debouncedOnClick('report-bar')"
          >
            <ViewIcon
              type="report"
              report-type="bar-chart"
            />
            <div>Bar Chart</div>
          </div>
          <div
            data-view="report"
            data-quantity="many"
            class="add-view-type"
            :class="viewTypeClasses"
            data-report="pie"
            @click="debouncedOnClick('report-pie')"
          >
            <ViewIcon
              type="report"
              report-type="pie-chart"
            />
            <div>Pie Chart</div>
          </div>
          <div
            data-view="report"
            data-quantity="many"
            class="add-view-type"
            :class="viewTypeClasses"
            data-report="line"
            @click="debouncedOnClick('report-line')"
          >
            <ViewIcon
              type="report"
              report-type="line-chart"
            />
            <div>Line Chart</div>
          </div>
          <div
            data-view="report"
            data-quantity="many"
            class="add-view-type"
            :class="viewTypeClasses"
            data-report="area"
            @click="debouncedOnClick('report-area')"
          >
            <ViewIcon
              type="report"
              report-type="area-chart"
            />
            <div>Area Chart</div>
          </div>
          <div
            data-view="report"
            data-quantity="many"
            class="add-view-type"
            :class="viewTypeClasses"
            data-report="pivot"
            @click="debouncedOnClick('report-pivot')"
          >
            <ViewIcon
              type="report"
              report-type="pivot-table"
            />
            <div>Pivot Table</div>
          </div>
        </div>
      </template>
    </Toggle>

    <Toggle
      v-if="canShowEcommerce || canShowCustomer"
      :is-open="true"
      toggle-trigger-classes="pt-4 pb-3"
    >
      <template #title>
        <span class="text-base font-semibold text-default">
          E-Commerce
        </span>
      </template>
      <template #content>
        <div :class="viewsListClasses">
          <div
            v-if="canShowEcommerce"
            v-tippy
            content="Accept a new payment"
            data-view="ecommerce"
            data-quantity="one"
            class="add-view-type"
            :class="viewTypeClasses"
            @click="debouncedOnClick('checkout', 'one')"
          >
            <ViewIcon type="accept_payment" />
            <div>Payment</div>
          </div>
          <div
            v-if="canShowCustomer"
            v-tippy
            content="Update a user's payment method"
            data-view="ecommerce"
            data-quantity="one"
            class="add-view-type"
            :class="viewTypeClasses"
            @click="debouncedOnClick('customer', 'one')"
          >
            <ViewIcon type="update_payment" />
            <div>Payment Method</div>
          </div>
        </div>
      </template>
    </Toggle>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import { Draggable } from '@shopify/draggable';
import ViewIcon from '@/components/pages/page/ViewIcon';
import PageUtils from '@/components/pages/PageUtils';
import Toggle from '@/components/ui/Toggle';
import IfPlan from '@/components/util/IfPlan';
import { splitGroupColumn } from '@/lib/page/page-groups-helper';
import Icon from '@/components/ui/Icon.vue';
import UpgradeButton from '@/components/ui/UpgradeButton.vue';

export default {
  components: {
    UpgradeButton,
    Icon,
    IfPlan,
    ViewIcon,
    Toggle,
  },
  mixins: [
    PageUtils,
  ],
  props: {
    mode: {
      type: String,
      default: 'insert', // insert | edit
    },
  },
  emits: [
    'submit',
  ],
  data() {
    return {
      draggable: null,
      activeDragSlot: null,
      debouncedOnClick: null,
    };
  },
  computed: {
    ...mapGetters([
      'isUsersEnabled',
      'getObject',
    ]),
    canShowRecordViews() {
      if (this.page.isLoginPage()) {
        return false;
      }

      return true;
    },
    canShowDetails() {
      if (this.page.isLoginPage()) {
        return false;
      }

      if (this.page.object) {
        return true;
      }

      // if no page object but requires authentication, this can show details about the logged-in user
      if (this.page.requiresAuthentication()) {
        return true;
      }

      return false;
    },
    canShowManyViews() {
      if (this.page.isLoginPage()) {
        return false;
      }

      // start page, so can show all records for every object
      if (!this.page.object) {
        return true;
      }

      // check for connected manys
      if (this.page.hasAnyConnectionsToMany()) {
        return true;
      }

      // from the user roles this page has access to
      if (this.page.hasAnyUserConnectionsToMany()) {
        return true;
      }

      return false;
    },
    canShowEcommerce() {
      if (this.page.isLoginPage() || !this.canShowDetails) {
        return false;
      }

      // True if this page knows about an object with a numeric field
      return this.page.canAddEcommView();
    },
    canShowCustomer() {
      // True if this page is protected by a login
      if (this.isUsersEnabled && this.page.requiresAuthentication()) {
        return true;
      }

      // True if this page knows about the user object
      return this.page.canAddPaymentMethodView();
    },
    canShowMaps() {
      if (this.page.isLoginPage()) {
        return false;
      }

      return this.page.canAddMapView();
    },
    canOptInToMaps() {
      if (this.page.isLoginPage()) {
        return false;
      }

      return this.page.canOptInToMapView();
    },
    canShowCalendars() {
      if (this.page.isLoginPage()) {
        return false;
      }

      return this.page.canAddCalendarView();
    },
    canShowReports() {
      if (this.page.isLoginPage()) {
        return false;
      }

      return true;
    },
    viewTypeClasses() {
      return 'w-[88px] h-[88px] text-default text-sm leading-4 justify-center rounded-lg border border-solid border-default hover:text-emphasis hover:border-brand-600 hover:bg-brand-50 m-0';
    },
    viewTypeInactiveClasses() {
      return 'flex items-center justify-center gap-3 text-default text-center font-medium text-sm leading-4 rounded-lg border border-solid border-[#e7e4e6] w-[192px] pt-[.8rem] pb-[.8rem] pl-[1.2rem] pr-[1.2rem] m-0';
    },
    viewsListClasses() {
      return 'add-views-list gap-4 ml-0 border border-solid border-x-0 border-t-0 border-b-subtle';
    },
  },
  watch: {
    // once an initial type is dragged, you can return to this step to edit the type.
    mode(newVal) {
      if (newVal === 'edit') {
        this.draggable.destroy();
        this.draggable = null;
      }
    },
  },
  created() {
    // Assigns a debounced callback to `this.debouncedOnClick` to limit how often the
    // corresponding click event handler can be executed in order to prevent double click defect.
    // Created as a property on the instance so that each instance of the
    // component gets its own independent debouncing tracking.
    this.debouncedOnClick = debounce(this.onClick, 300, {
      leading: true,
      trailing: false,
    });
  },
  unmounted() {
    if (!this.draggable) {
      return;
    }

    this.destroyDragDrop();
  },
  methods: {
    onMouseEnter() {
      if (!this.isDragging && !this.draggable) {
        this.mountDragDrop();
      }
    },
    onMouseLeave() {
      if (!this.isDragging && this.draggable) {
        this.destroyDragDrop();
      }
    },
    mountDragDrop() {
      this.draggable = new Draggable([
        document.querySelector('#page-views'),
        ...document.querySelectorAll('.add-views-list'),
      ], {
        draggable: '.add-view-type, .drop-target',
        handle: '.add-view-type',
        classes: {
          'body:dragging': 'is-dragging-over',
          'draggable:over': 'over',
          'container:dragging': 'is-dragging-over',
          'container:over': 'is-dragging-over',
        },
        appendTo: 'body',
        delay: 200,
      });

      this.draggable.on('drag:start', () => {
        this.isDragging = true;

        this.$store.commit('dragNewViewStart');
      });

      this.draggable.on('drag:over', (event) => {
        if (!event.over.classList.contains('drop-target')) {
          log('not a drop target!!');

          return;
        }

        this.activeDragSlot = event.over;
      });

      this.draggable.on('drag:out', (event) => {
        this.activeDragSlot = null;
      });

      this.draggable.on('drag:stop', (event) => {
        this.isDragging = false;

        log('Stop', event, this.activeDragSlot);

        this.$store.commit('dragNewViewEnd');

        if (this.activeDragSlot === null) {
          return;
        }

        const viewType = event.source.getAttribute('data-view');
        const reportType = event.source.getAttribute('data-report');
        const recordsQuantity = event.source.getAttribute('data-quantity');

        let groupIndex = this.activeDragSlot.getAttribute('data-group') || 0;
        let columnIndex = this.activeDragSlot.getAttribute('data-column') || 0;
        const itemIndex = this.activeDragSlot.getAttribute('data-item') || 0;

        const dropTargetType = this.activeDragSlot.getAttribute('data-target-type') || 'column-same';

        // where are we going to insert?
        let addLocationType = 'item';

        if (dropTargetType === 'group-new') {
          addLocationType = 'group';
        }

        if (dropTargetType === 'column-split') {
          // split the column. ViewAdd.vue is responsible for commiting this to the server
          this.page.groups = splitGroupColumn(this.page.groups, groupIndex, columnIndex, itemIndex);

          groupIndex++;

          columnIndex = (this.activeDragSlot.classList.contains('left')) ? 0 : 1;

          addLocationType = 'column-split';
        }

        if (this.activeDragSlot.classList.contains('vertical')) {
          addLocationType = 'column';
        }

        // emit
        this.$emit('submit', {
          viewType, reportType, recordsQuantity, addLocationType, groupIndex, columnIndex, itemIndex,
        });
      });
    },
    destroyDragDrop() {
      this.draggable.destroy();
      this.draggable = null;
    },
    onClick(selectedViewType, recordsQuantity = 'many') {
      const viewType = selectedViewType.includes('report-') ? 'report' : selectedViewType;

      const reportType = selectedViewType.includes('report-') ? selectedViewType.replace('report-', '') : null;

      // selectedViewType: used for distinguishing reports
      return this.$emit('submit', {
        viewType,
        reportType,
        recordsQuantity,
        addLocationType: 'group',
        groupIndex: this.page.groups.length,
        columnIndex: 0,
        itemIndex: 0,
      });
    },
  },
};
</script>

<style lang="scss">
#add-view {
  h3 {
    @include font-body;
    font-weight: 500;
    margin: 0;

    * {
      color: $primary500;
    }
  }
  .add-views-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -.75em;
  }
}

div.add-view-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  vertical-align: center;
  background-color: none;
  border-radius: .5rem;
  padding: .5rem .25rem;
  margin-left: .75rem;
  margin-bottom: .5rem;
  cursor: pointer;
  width: 88px;
  @include font-body;
  font-weight: 500;
  text-align: center;
  border: 1px solid transparent;

  .view-icon {
    margin-bottom: 0;
  }

  &:hover {
    background-color: $white100;
    border: 1px solid $gray200;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}
</style>
