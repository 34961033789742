import axios from 'axios';
import { Crisp } from 'crisp-sdk-web';

// eslint-disable-next-line import/prefer-default-export
export async function showCrispChat() {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email, first_name, last_name } = window.Knack.Api;
  const websiteId = process.env.VUE_APP_CRISP_ID;
  if (!window.$crisp && email && websiteId) {
    Crisp.configure(websiteId);
    try {
      const signature = await axios.post(`${process.env.VUE_APP_API_URL}crisp-auth/identify`, { email });
      if (signature) {
        Crisp.user.setEmail(email, signature);
        Crisp.user.setNickname(`${first_name} ${last_name}`);
      }
    } catch (error) {
      console.log('error', error);
    }
  }
}

export function hideCrispChat() {
  if (window.$crisp) {
    Crisp.chat.hide();
  }
}
