<template>
  <nav
    id="pages-nav"
    ref="pagesNav"
    class="toolbox-body left-toolbox bg-muted"
  >
    <div class="title-wrapper p-4 m-0">
      <div class="title roleFilter border-b-0 h-fit">
        <Popover
          v-if="showRoleFilter"
          placement="bottom-end"
        >
          <template #trigger>
            <h3
              v-tippy
              content="Filter which pages to show"
              class="roleFilter__trigger text-emphasis"
              data-cy="page-filter-menu"
            >
              {{ activeRole.name }}
              <Icon
                type="filter-indicator"
                class="small roleFilter__indicator text-default ml-2"
                :class="{ 'roleFilter__indicator--active': activeRole.key !== 'all' }"
              />
            </h3>
          </template>
          <template #content>
            <div class="links roleFilter__content py-0">
              <a
                v-for="role in roleFilterOptions"
                :key="role.key"
                v-close-popper
                class="rounded hover:bg-brand-50 text-base text-default p-2"
                @click="onFilterPagesByRole(role)"
              >
                <Icon
                  :type="role.icon"
                  class="small"
                /> {{ role.name }}
              </a>
            </div>
          </template>
        </Popover>
        <h3
          v-else
          class="text-emphasis"
        >
          All Pages
        </h3>

        <Popover placement="bottom-end">
          <template #trigger>
            <div
              v-tippy
              v-close-popper
              content="Add a new page or dropdown menu"
              class="button-icon-square add"
              :class="addButtonClasses"
              style="cursor: pointer;"
              data-cy="add-new-page"
            >
              <Icon
                class="h-4 w-4 hover:fill-current"
                type="add"
              />
            </div>
          </template>

          <template #content>
            <div
              id="add-page-menu"
              class="links p-0"
              style="cursor: pointer;"
            >
              <h3 class="text-subtle text-sm m-0 p-2 border-b-0 cursor-default">
                Add a New
              </h3>
              <template v-if="isGlobalLogin">
                <router-link
                  v-close-popper
                  :class="listItemClasses"
                  to="/pages/add/public"
                  data-cy="add-public-page"
                >
                  <Icon
                    class="text-inherit"
                    type="lock"
                  /> Page
                </router-link>
              </template>
              <template v-else>
                <router-link
                  v-close-popper
                  :class="listItemClasses"
                  to="/pages/add/public"
                  data-cy="add-public-page"
                >
                  <Icon
                    type="page"
                    class="small text-inherit"
                  /> Public Page
                </router-link>
                <router-link
                  v-close-popper
                  :class="listItemClasses"
                  to="/pages/add/login"
                  data-cy="add-login-page"
                >
                  <Icon
                    class="text-inherit"
                    type="lock"
                  /> Login Page
                </router-link>
              </template>
              <router-link
                v-close-popper
                :class="listItemClasses"
                to="/pages/add/menu"
                data-cy="add-dropdown-menu"
              >
                <Icon
                  type="dropdown-menu"
                  class="small text-inherit"
                /> Dropdown Menu
              </router-link>
            </div>
          </template>
        </Popover>
      </div>
    </div>
    <PageListSortable
      id="page-links"
      :key="`${activeRole.slug}-pagesList`"
      data-cy="page-links"
      :can-sort="canSortPages"
    >
      <PageLink
        v-for="item of pageList"
        :key="item.key"
        :page="item"
        :route-query="routeQuery"
        :is-sortable="canSortPages"
        :children-are-sortable="!canSortPages"
        :filter-matches="activeRole.matchCases"
      />
    </PageListSortable>

    <div class="title-wrapper p-4 m-0">
      <div class="title border-b-0 h-fit">
        <h3 class="text-emphasis">
          User Pages
        </h3>
        <router-link
          v-tippy
          content="Add a new user page"
          to="/pages/add/user"
          class="button-icon-square add"
          :class="addButtonClasses"
          data-cy="add-new-user-page"
        >
          <Icon
            class="h-4 w-4 hover:fill-current"
            type="add"
          />
        </router-link>
      </div>
    </div>

    <PageListSortable
      v-if="userPages.length"
      :key="`${activeRole.slug}-userPagesList`"
      class="margin-bottom-double"
      data-test-id="user-page-links"
      :can-sort="false"
    >
      <PageLink
        v-for="item of userPages"
        :key="item.key"
        :page="item"
        :route-query="routeQuery"
        :filter-matches="activeRole.matchCases"
      />
    </PageListSortable>
  </nav>
</template>

<script>
import hasIn from 'lodash/hasIn';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import { mapActions, mapGetters } from 'vuex';

import Icon from '@/components/ui/Icon';
import PageLink from '@/components/pages/PageLink';
import PageNavUtils from '@/components/pages/PageNavUtils';
import UIUtil from '@/util/UI';
import { pageDragSetup, pageDragTeardown } from '@/components/pages/dragPages';
import filterPages from '@/components/pages/filterPages';
import PageListSortable from '@/components/pages/PageListSortable';
import Popover from '@/components/ui/Popover';
import RequestUtils from '@/components/util/RequestUtils';

export default {
  name: 'PagesNav',
  components: {
    PageLink,
    PageListSortable,
    Popover,
    Icon,
  },
  mixins: [
    PageNavUtils,
    RequestUtils,
  ],
  computed: {
    ...mapGetters([
      'activePage',
      'activeRoleFilter',
      'isGlobalLogin',
      'getPageByKey',
    ]),
    ...mapGetters('page', [
      'navPages',
    ]),
    activeRole() {
      return this.activeRoleFilter || this.roleFilterOptions[0];
    },
    routeQuery() {
      return this.$store.getters['routes/getQuery']('pages');
    },
    userPages() {
      return filterPages({
        pages: this.navPages.user,
        filterMatches: this.activeRole.matchCases,
      });
    },
    pageList() {
      return filterPages({
        pages: this.navPages.main,
        filterMatches: this.activeRole.matchCases,
      });
    },
    canSortPages() {
      // Do not allow the global login page to be sorted.
      return !this.pageList[0]?.isGlobalLoginPage();
    },
    addButtonClasses() {
      return 'flex px-1 py-3 justify-center items-center rounded text-white bg-gradient-primary';
    },
    listItemClasses() {
      return 'rounded-lg hover:bg-brand-50 text-base text-default hover:text-emphasis';
    },
  },
  watch: {
    activePage(newVal) {
      if (newVal) {
        this.scrollToActivePage();
      }
    },
  },
  created() {
    // Setup dragging for the page nav pages.
    pageDragSetup();

    // If we've gotten to this page with a query string but no activeRoleFilter assign it
    if (!this.activeRoleFilter && hasIn(this.$route.query, 'role')) {
      const role = this.roleFilterOptions.find((option) => option.slug === this.$route.query.role);

      if (!isNil(role)) {
        this.$store.commit('activeRoleFilter', role);
        return;
      }

      // if Role is undefined query doesn't match any existing role option, reset query
      this.$store.commit('routes/resetQueries', 'role');
      this.$router.push(omit(this.$route.query, 'role'));
    }
  },
  mounted() {
    this.scrollToActivePage();
  },
  unmounted() {
    // Prevent drag memory leaks.
    pageDragTeardown();
  },
  methods: {
    ...mapActions('page/navVisibility', [
      'showNavChildren',
    ]),
    async scrollToActivePage() {
      // let other processing happen first, this isn't as important
      await this.$nextTick();

      // make sure the parents of any selected page are open
      let togglePage = this.activePage;

      while (togglePage && togglePage.key) {
        if (togglePage.hasChildPages()) {
          await this.showNavChildren(togglePage.key);
        }

        const parentPage = togglePage.getParentPage();

        if (parentPage) {
          togglePage = this.getPageByKey(parentPage.key);
        } else {
          togglePage = null;
        }
      }

      // need to await here to ensure any active child page is open from toggling above
      await this.$nextTick();

      // select the DOM element of the activ elink
      const $active = this.$el.querySelector?.('.nav-item.router-link-active');

      if (!$active) {
        return;
      }

      // check if it's visible in the upper section of the navigation panel
      const rect = $active.getBoundingClientRect();

      const isVisible = (rect.top >= 0) && (rect.bottom <= window.innerHeight - 200);

      if (isVisible) {
        return;
      }

      // active page is not visible, so let's move it into view
      const scrollable = new UIUtil.ScrollTo(this.$refs.pagesNav);

      const scrollDestination = rect.top - ((window.innerHeight / 2) - 50);

      scrollable.go(scrollDestination);
    },
    onFilterPagesByRole(role) {
      const { role: prevSlug, ...otherQueries } = this.$route.query;
      const { slug } = role;
      const isSlugEmpty = isEmpty(slug);

      // if slug is empty remove role from the url entirely
      const query = (isSlugEmpty) ? otherQueries : {
        ...otherQueries,
        role: slug,
      };

      this.$router.push({
        query,
      });

      if (!isSlugEmpty) {
        // Sync the query back to the persisted state
        this.$store.commit('routes/updateQuery', {
          queryType: 'pages',
          queryKey: 'role',
          queryValue: slug,
        });

        return this.$store.commit('activeRoleFilter', role);
      }

      // Slug is empty, remove role from persisted route and store
      if (!isNil(prevSlug)) {
        this.$store.commit('routes/resetQueries', 'role');
      }

      return this.$store.commit('activeRoleFilter', false);
    },

    /**
       * Calculates actual new index of page in full page tree on page sort
       * If we're moving the page to the top of the list we'll find the page at the top of the visible list and add our page directly before it
       * Otherwise we'll find the page directly preceeding our page in the visible list and add our page directly after it
       *
       * @param {RawPage} page - The {@link RawPage} being sorted
       * @param {number} dragNewIndex - The newIndex returned by the drag/drop event, this only accounts for the visible pages
       * @param {array} filteredSortedPages - the visible page tree being manipulated, used to find sibling pages for positioning
       *
       * @return {number} The actual index the page should be inserted at in the full page list
       */
    getUnFilteredPageNewIndex(page, dragNewIndex, filteredSortedPages) {
      const pageOrder = this.pages().map((page) => page.key);
      let newIndex;

      switch (dragNewIndex) {
        case 0: {
          // moving to top of list, find next page in visible list and place immediately before
          const nextFilteredPageIndex = pageOrder.indexOf(filteredSortedPages[1].key);

          newIndex = nextFilteredPageIndex !== 0 ? nextFilteredPageIndex : 0;

          break;
        }

        default: {
          // moving to middle of list, find previous page in visible list and place immediately after
          const prevFilteredPageIndex = pageOrder.indexOf(filteredSortedPages[dragNewIndex - 1].key);

          newIndex = prevFilteredPageIndex + 1;
        }
      }

      return newIndex;
    },
  },
};
</script>

<style lang="scss">
$tree-padding: 20px;
$tree-left: 4;

#page-links > li {
  margin-bottom: 6px;
}

.roleFilter__content {
  max-width: 250px;
}

#pages-nav {
  padding: 0;

  &>ul {

    >li {
      margin: 0 .5em;
      // margin-bottom: .5em;
    }
  }

  li > a { padding-left: $tree-padding - $tree-left; }
  li li > a { padding-left: $tree-padding * 2 - $tree-left; }
  li li li > a { padding-left: $tree-padding * 3 - $tree-left; }
  li li li li > a { padding-left: $tree-padding * 4 - $tree-left; }
  li li li li li > a { padding-left: $tree-padding * 5 - $tree-left; }
  li li li li li li > a { padding-left: $tree-padding * 6 - $tree-left; }
  li li li li li li li > a { padding-left: $tree-padding * 7 - $tree-left; }
  li li li li li li li li > a { padding-left: $tree-padding * 8 - $tree-left; }

  .title {

    h3 {
      font-weight: 500;
    }
  }

  .title-wrapper {
    background-color: inherit;
  }

  .roleFilter__trigger {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  svg.roleFilter__icon {
    width: 18px;
    height: 18px;
    color: $gray700;
  }
}

#page-filters {
  margin-left: 8px;
  padding: 0;
  color: #9c9da2;

  svg {
    position: relative;
    top: 3px;
  }
}
</style>
