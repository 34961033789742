<template>
  <div
    id="records"
    class="builderLayout_builderPane"
    :class="{'builderPane-noToolbox': !hasObjects }"
    @mousemove="handleLeftPanelResizeOnDrag"
    @mouseup="cancelLeftPanelResizing"
    @mouseleave="cancelLeftPanelResizing"
  >
    <RouterView name="modal" />

    <ToolboxRouter
      v-if="hasObjects"
      class="main-panel builderLayout_toolbox"
      :class="toolboxWidth"
      :style="{ flexBasis: leftPanelFlexBasis }"
    />

    <PanelDivider />

    <div
      id="records-body-wrapper"
      class="main-body builderLayout_main  border-solid border-x-0 border-b-0 border-t border-subtle -ml-px"
    >
      <RecordsRibbon
        v-if="showRibbon"
        class="builderLayout_ribbon border-0"
        :has-records="hasRecords"
      />

      <BodyWrapper
        id="records-body"
        class="disabled"
      >
        <RouterView
          name="body"
          @update-total-records-count="updateHasRecords"
        />
      </BodyWrapper>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
} from 'vuex';
import BodyWrapper from '@/components/layout/BodyWrapper';
import PanelDivider from '@/components/layout/PanelDivider';
import ToolboxRouter from '@/components/layout/ToolboxRouter';
import RecordsRibbon from '@/components/records/RecordsRibbon';

export default {
  components: {
    BodyWrapper,
    ToolboxRouter,
    RecordsRibbon,
    PanelDivider,
  },
  beforeRouteUpdate(to) {
    this.showImport = to.meta.showImport || false;

    this.setPanelClasses();
  },
  props: {
    layout: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showImport: false,
      leftPanelClass: 'medium-panel',
      hasRecords: false,
    };
  },
  computed: {
    ...mapGetters([
      'objects',
    ]),

    ...mapGetters('ui', [
      'leftPanelFlexBasis',
      'leftPanelWidth',
    ]),
    ...mapGetters('notifications', [
      'activeSocketNotification',
    ]),
    toolboxWidth() {
      return this.$store.getters.toolboxExpandClass(this.$route);
    },

    hasObjects() {
      return this.objects.length > 0;
    },
    showRibbon() {
      return this.hasObjects && this.activeObject;
    },
    activeObject() {
      if (this.$route.params.objectKey) {
        return this.$store.getters.getObject(this.$route.params.objectKey);
      }

      return false;
    },
  },
  created() {
    this.setPanelClasses();
  },
  mounted() {
    if (!this.leftPanelWidth) {
      this.setInitialLeftPanelWidth();
    }
  },
  methods: {
    ...mapActions('ui', [
      'handleLeftPanelResizeOnDrag',
      'cancelLeftPanelResizing',
      'setInitialLeftPanelWidth',
    ]),
    onClickImport() {
      this.$router.push(`${this.$route.path}/import`);
    },
    setPanelClasses() {
      // batches need more room
      this.leftPanelClass = 'medium-panel';

      if (this.$route.path.indexOf('/batch/') > -1) {
        this.leftPanelClass = 'large-panel';
      }
    },
    updateHasRecords(totalRecords) {
      this.hasRecords = totalRecords > 0;
    },
  },
};
</script>

<style lang="scss">
</style>
