import {
  computed, reactive, toRefs, watch,
} from 'vue';
import { useStore } from 'vuex';

interface ChecklistSection {
  id: string;
  steps: Record<string, ChecklistStep>;
}

interface ChecklistStep {
  completed_at: string | null;
  definition: {
    id: string;
    blocked_by?: string;
  };
}

export default function useChecklists() {
  const store = useStore();

  const checklists = computed(() => store.getters['checklists/data']);

  const state = reactive({
    sections: {} as Record<string, ChecklistSection & { steps: ChecklistStep[]; completed: number }>,
    allCompletedSteps: new Set<string>(),
    totalCount: 0,
  });

  const initialize = () => {
    const bySection: Record<string, ChecklistSection & { steps: ChecklistStep[]; completed: number }> = {};
    let all: string[] = [];

    Object.values(checklists.value?.sections ?? {}).forEach((section: any) => {
      const steps = Object.values(section.steps);
      const completedSteps = steps.filter((step: any) => !!step.completed_at).map((step: any) => step.definition.id);

      bySection[section.id] = {
        ...section,
        steps,
        completed: completedSteps.length,
      };

      all = all.concat(completedSteps);
    });

    state.sections = bySection;
    state.allCompletedSteps = new Set(all);
    state.totalCount = Object.values(bySection).flatMap((section) => Object.keys(section.steps)).length;
  };

  watch(checklists, () => {
    initialize();
  }, { immediate: true });

  const isStepCompleted = (stepId: string): boolean => state.allCompletedSteps.has(stepId);

  const getSectionById = (sectionId: string) => state.sections[sectionId];

  const isStepLocked = (step: ChecklistStep): boolean => !!step.definition.blocked_by && !state.allCompletedSteps.has(step.definition.blocked_by);

  const isSectionCompleted = (sectionId: string): boolean => !state.sections[sectionId].steps.some((step: ChecklistStep) => step.completed_at === null);

  const isSectionNotStarted = (sectionId: string): boolean => !state.sections[sectionId].steps.some((step: ChecklistStep) => typeof step.completed_at === 'string');

  return {
    ...toRefs(state),
    isStepCompleted,
    getSectionById,
    isStepLocked,
    isSectionCompleted,
    isSectionNotStarted,
    totalCompleted: computed(() => state.allCompletedSteps.size),
  };
}
