<template>
  <div
    id="step-source"
    class="w-[480px] mt-12"
  >
    <h1 class="center center-align text-left text-emphasis text-xl font-medium mb-1 mt-0">
      Import a File
    </h1>
    <p class="text-default text-sm font-normal mb-6">
      Upload your own file or select a Google Sheet to import
    </p>

    <div>
      <div
        v-if="tab === 'upload'"
        class="box margin-bottom-double mb-0 p-0 bg-transparent shadow-none"
      >
        <div class="center-align padding-top p-0">
          <form class="fileUpload fileUpload-primary">
            <div class="dropbox flex-center fileUpload_dropzone relative flex flex-col items-center justify-center rounded-lg border-2 border-dashed border-pink-600 p-6 bg-gradient-1-white bg-transparent min-h-[160px] mb-0">
              <input
                type="file"
                :name="uploadFieldName"
                :disabled="isUploading"
                :accept="allowedLocalImports"
                class="input-file h-40 p-0"
                data-cy="import-file-upload"
                @change="fileChanged($event.target.name, $event.target.files); fileCount = $event.target.files.length"
              >
              <Icon
                class="text-default h-10 w-10"
                type="cloud-import"
              />
              <div className="text-center">
                <p className="mb-2 font-semibold text-emphasis p-0 text-base">
                  Drag or click here to upload file
                </p>
                <div className="text-default">
                  <p class="text-sm p-0 mb-0">
                    .csv, .xls, .xlsx
                  </p>
                  <p class="text-sm p-0 mb-0">
                    250 MB max file size
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div>
        <h1 class="center-align text-base text-default font-semibold my-6">
          Or
        </h1>
        <div
          class="box margin-bottom-double flex-container margin-top-double m-0 p-2 shadow-none border border-solid border-default hover:cursor-pointer"
          @click="createPicker"
        >
          <div class="title-with-icon">
            <img
              :src="googleIcon"
              alt="Google Sheets Icon"
              class="google-icon"
            >
            <h3 class="text-empasis text-base font-semibold">
              Google Sheets
            </h3>
          </div>
        </div>
        <GlobalLoading :is-loading="isLoading" />
      </div>

      <div
        v-if="tab === 'paste'"
        class="box margin-bottom-double"
      >
        <PasteInput @submit="onSubmit" />
      </div>
      <p
        class="center-align margin-bottom-double mt-12"
        style="font-size: .875rem;"
      >
        View the <a
          href="https://learn.knack.com/article/ejdb2toq4i-import-records"
          target="_blank"
          class="underline text-default"
        >imports guide</a> to learn more about imports, and see our
        <a
          href="https://learn.knack.com/article/limited-use-disclosure"
          target="_blank"
          class="underline"
        >limited use disclosure</a> for Google Sheets imports.
      </p>
    </div>
  </div>
</template>

<script>
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import Icon from '@/components/ui/Icon';
import PasteInput from '@/components/ui/inputs/ImportPasteInput';
import GoogleIcon from '@/assets/google-sheets.png';
import GlobalLoading from '@/components/ui/GlobalLoading';

const SpreadsheetFormats = {
  CSV: 'text/csv',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS: 'application/vnd.ms-excel',
  GOOGLE_SPREADSHEET: 'application/vnd.google-apps.spreadsheet',
};

export default {
  components: {
    Icon,
    PasteInput,
    GlobalLoading,
  },
  emits: [
    'submit',
  ],
  data() {
    return {
      tab: 'upload',
      uploadFieldName: 'files',
      googleIcon: GoogleIcon,
      googleScopes: 'https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/spreadsheets.readonly',
      sheetData: [],
      tokenClient: null,
      accessToken: null,
      pickerInited: false,
      gisInited: false,
      isLoading: false,
    };
  },
  computed: {
    isUploading() {
      return false;
    },
    allowedLocalImports() {
      return [
        SpreadsheetFormats.CSV,
        SpreadsheetFormats.XLSX,
        SpreadsheetFormats.XLS,
      ];
    },
    allowedGoogleImports() {
      return [SpreadsheetFormats.GOOGLE_SPREADSHEET];
    },
  },
  mounted() {
    this.$store.dispatch('loadGoogleAPI').then(() => {
      this.onApiLoad();
    });

    this.$store.dispatch('loadGoogleGSI').then(() => {
      this.gisLoaded();
    });
  },
  methods: {
    onSubmit(data) {
      log('ImportSource.onSubmit()');
      this.$emit('submit', data);
    },
    fileChanged(fieldName, fileList) {
      log('Importsource.fileChanged()');

      if (isEmpty(fileList)) {
        log('fileList was empty, returning!');

        return;
      }

      const targetFile = fileList[0];

      if (isNil(targetFile)) {
        log('the first item in fileList was nil, returning!');

        return;
      }

      const reader = new FileReader();

      reader.onload = async (element) => {
        let parsedData;
        let totalRecords;
        let previewData;

        try {
          if (targetFile.type === SpreadsheetFormats.CSV) {
            // Using 'greedy' means it skips delimiters, quotes, and whitespace
            parsedData = Papa.parse(element.target.result, { skipEmptyLines: 'greedy' });
            if (!parsedData || !parsedData.data) {
              throw new Error('Error parsing CSV file');
            }
            totalRecords = parsedData.data.length;
            previewData = parsedData.data.slice(0, 50).map((row) => row.map((cell) => cell.trim().replace(/^\"|\"$/g, '')));
          } else if (targetFile.type === SpreadsheetFormats.XLS || targetFile.type === SpreadsheetFormats.XLSX) {
            const workbook = XLSX.read(element.target.result, { type: 'binary', cellFormula: true, sheets: [0] });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            parsedData = XLSX.utils.sheet_to_json(worksheet, {
              header: 1, blankrows: false, defval: '', raw: false,
            }).map((row) => row.map((cell) => (cell.trim())));
            if (!parsedData) {
              throw new Error('Error parsing Excel file');
            }
            totalRecords = parsedData.length;
            previewData = parsedData.slice(0, 50);
          } else {
            throw new Error(`Unsupported file type: ${targetFile.type}`);
          }
        } catch (error) {
          log(error);
        }

        const data = {
          file: targetFile,
          sheets: [{
            rowCount: totalRecords,
            preview: previewData,
          }],
        };
        return this.onSubmit(data);
      };

      if (targetFile.type === SpreadsheetFormats.CSV) {
        reader.readAsText(targetFile);
      } else if (targetFile.type === SpreadsheetFormats.XLS || targetFile.type === SpreadsheetFormats.XLSX) {
        reader.readAsBinaryString(targetFile);
      } else {
        log(`Unsupported file type: ${targetFile.type}, returning!`);
      }
    },
    onApiLoad() {
      gapi.load('picker', this.onPickerApiLoad);
    },
    onPickerApiLoad() {
      this.pickerInited = true;
    },
    gisLoaded() {
      this.tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: process.env.VUE_APP_GOOGLE_ID,
        scope: this.googleScopes,
      });
      this.gisInited = true;
    },
    createPicker() {
      const showPicker = () => {
        const docsView = new google.picker.DocsView(google.picker.ViewId.SPREADSHEETS);
        docsView.setMode(google.picker.DocsViewMode.LIST);
        docsView.setMimeTypes(this.allowedGoogleImports.join(','));

        const picker = new google.picker.PickerBuilder()
          .addView(docsView)
          .setOAuthToken(this.accessToken)
          .setCallback(this.pickerCallback)
          .build();

        picker.setVisible(true);
      };

      this.tokenClient.callback = async (response) => {
        if (response.error !== undefined) {
          throw response;
        }
        this.accessToken = response.access_token;
        showPicker();
      };

      try {
        if (this.accessToken === null) {
          this.tokenClient.requestAccessToken({ prompt: 'consent' });
        } else {
          this.tokenClient.requestAccessToken({ prompt: '' });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error in createPicker:', error);
      }
    },
    async pickerCallback(data) {
      try {
        if (typeof google === 'undefined' || !google.picker) {
          return;
        }

        const { Action, Response, Document } = google.picker;

        if (data[Response.ACTION] !== Action.PICKED) {
          return;
        }
        this.isLoading = true;

        const selectedDoc = data[Response.DOCUMENTS][0];
        // eslint-disable-next-line max-len
        const sheetDataResponse = await window.Knack.Api.getGoogleSheetsPreview(selectedDoc[Document.ID], this.accessToken);

        const processedData = {
          sheets: sheetDataResponse,
          file: selectedDoc,
          token: this.accessToken,
        };

        this.onSubmit(processedData);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error in pickerCallback:', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
#step-source {

  width: 800px;
  margin: auto;
  margin-top: 1em;

  &> h1 {
    margin: 1.5em auto;
    @include font-h2;

  }

  .icon-upload {
    height: 175px;
    width: 72px;
  }

  form {
    margin: auto;
    max-width: 500px;
  }

  .dropbox {
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    margin-bottom: .54em;
    cursor: pointer;
  }

  svg {
    height: 48px;
    width: 48px;
  }

  .flex-container {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  .title-with-icon > h3 {
    margin: 0;
  }

  .title-with-icon {
    display: flex;
    align-items: center;
  }

  .google-icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    left: 0;
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }

  .instruction p {
    margin-bottom: 0;
  }

  .google-button {
    width: 121px;
    height: 40px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #D3CFD2;
    background-color: #FFFFFF;
    transition: background-color 0.3s;
  }

  .google-button:hover {
    background-color: #f5f5f5;
  }
}
</style>
