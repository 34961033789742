<template>
  <div
    id="settings-js"
    class="mb-10"
    style="max-width: 100%;"
  >
    <form id="code-editor-form-js mb-0">
      <p class="instructions-js text-default text-base mb-6 max-w-xl">
        Custom JavaScript will be called after the Knack app has been loaded.
        <a
          href="https://www.knack.com/developer-documentation/#jquery-amp-javascript"
          target="_blank"
          class="underline text-default"
          style="display: inline-block"
        >
          View our dev docs
        </a> to learn more.
      </p>
      <CodeEditor
        language="javascript"
        :editor-options="editorOptions"
        @save="onSave"
      />
    </form>
    <div class="flex mt-3">
      <a
        class="button medium save bg-gradient-primary rounded-lg border-0 p-3 text-base leading-4 font-medium"
        @click="onSave"
      >
        Save Code
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { eventBus } from '@/store/bus';
import CodeEditor from '@/components/util/CodeEditor';
import RequestUtils from '@/components/util/RequestUtils';
import { logEvent } from '@/lib/segment-helper';

export default {
  components: {
    CodeEditor,
  },
  data() {
    return {
      code: '',
      editorOptions: {
        roundedSelection: false,
        scrollBeyondLastLine: false,
        readOnly: false,
        tabSize: 2,
        theme: 'vs-dark',
      },
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
  },
  created() {
    eventBus.$on('javascriptCodeEditor:update', (newCode) => {
      this.code = encodeURIComponent(newCode);
    });
  },
  beforeUnmount() {
    eventBus.$off('javascriptCodeEditor:update');
  },
  methods: {
    onSave() {
      this.commitRequest({
        request: () => {
          const update = {
            settings: this.app.get('settings'),
          };

          update.settings.javascript = this.code;

          return this.app.update(update);
        },
        onSuccess: () => {
          logEvent('javascript_custom');
        },
      });
    },
  },
  mixins: [
    RequestUtils,
  ],
};
</script>

<style lang="scss">
#code-editor-form-js {
  margin-bottom: 1.25em;
  max-width: 100%;
  width: 100%;
}

.instructions-js {
  margin-bottom: 1em;
}
</style>
