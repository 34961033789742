<template>
  <div id="kn-builder">
    <div id="modal-1" />
    <div id="modal-2" />
    <ChecklistHighlightObserver />
    <ToastNotifications />
    <GlobalNotifications />
    <AssetViewer v-if="assetIsLoaded" />
    <OverlayBackground
      id="kn-login-overlay-background"
      style="display: none"
    >
      <Login ref="reauthenticationLogin" />
    </OverlayBackground>
    <template v-if="appIsLoaded || !$route.meta.requiresAuth">
      <RouterView />
    </template>
    <template v-else>
      <GlobalLoading :is-loading="isLoadingRequest" />
    </template>
    <SvgGradients />
  </div>
</template>

<script>
import LogRocket from 'logrocket';
import { mapGetters } from 'vuex';
import { generateCssVariables } from '@/styles/tailwind/theming';
import AssetViewer from '@/components/renderer/shared/AssetViewer';
import GlobalLoading from '@/components/ui/GlobalLoading';
import GlobalNotifications from '@/components/ui/notifications/GlobalNotifications';
import Login from '@/components/user/Login';
import OverlayBackground from '@/components/ui/OverlayBackground';
import { shouldEnableLogRocket } from '@/lib/env-helper';
import { getHSTokenAndShowChat } from '@/lib/hubspot-helper';
import SvgGradients from '@/components/ui/SvgGradients';
import { isTrial } from '@/lib/plan-helper';
import { showCrispChat } from '@/lib/crisp-helper';
import ChecklistHighlightObserver from '@/components/checklists/ChecklistObservers.vue';
import ToastNotifications from '@/components/ui/notifications/ToastNotifications.vue';

export default {
  components: {
    ToastNotifications,
    ChecklistHighlightObserver,
    AssetViewer,
    GlobalLoading,
    GlobalNotifications,
    Login,
    OverlayBackground,
    SvgGradients,
  },
  computed: {
    ...mapGetters([
      'appIsLoaded',
    ]),
    ...mapGetters('assets', [
      'assetIsLoaded',
    ]),
  },
  watch: {
    async appIsLoaded(newVal) {
      if (newVal === true && process.env.VUE_APP_IS_PRODUCTION === 'true') {
        if (isTrial()) {
          showCrispChat();
        } else {
          getHSTokenAndShowChat();
        }
      }
    },
  },
  mounted() {
    this.insertThemeStyles();
    document.documentElement.dataset.theme = 'light';
  },
  errorCaptured(err) {
    if (shouldEnableLogRocket()) {
      LogRocket.captureException(err);
    }
  },
  methods: {
    insertThemeStyles() {
      const id = 'theme-styles';
      if (!document.getElementById(id)) {
        document.head.insertAdjacentHTML(
          'beforeend',
          `<style id="${id}">${generateCssVariables()}</style>`,
        );
      }
    },
  },
};
</script>
