<template>
  <ul
    class="toc-links"
    data-cy="toolbox-links"
  >
    <li
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      :data-cy="item.title"
      class="[&:not(:last-child)]:mb-2"
    >
      <router-link
        :to="routeAppend($route.path, item.link)"
        class="p-2 rounded-lg group hover:bg-brand-50"
      >
        <div>
          <b class="inline-flex text-default text-base font-semibold group-hover:text-emphasis">
            {{ item.title }}<span
              v-if="item.filters"
              v-tippy
              content="Filters are limiting this view's records"
              class="badge h-6 w-6 p-1 rounded-md text-sm leading-4 items-center ml-1 bg-subtle"
            >
              <Icon
                class="h-4 w-4 group-hover:text-default"
                type="filter-funnel"
              />
            </span>
            <CountIndicator
              v-if="ruleCountMap[item.title]"
              class="ml-2 bg-subtle text-default"
              :count="ruleCountMap[item.title]"
            />
          </b>
          <p class="text-default text-xs m-0 mt-1 group-hover:text-emphasis">
            {{ item.copy }}
          </p>
        </div>
        <Icon
          type="arrow-forward"
          class="text-default group-hover:text-brand-400"
        />
      </router-link>
    </li>
  </ul>
</template>

<script>
import CountIndicator from '@/components/ui/CountIndicator';
import ViewUtils from '@/components/views/ViewUtils';
import Icon from '@/components/ui/Icon';

export default {
  components: {
    Icon,
    CountIndicator,
  },
  mixins: [
    ViewUtils,
  ],
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    totalDisplayRulesCount() {
      return this.viewRaw.rules?.fields?.length ?? 0;
    },
    totalSubmitRulesCount() {
      return this.viewRaw.rules?.submits?.length ?? 0;
    },
    totalRecordsRulesCount() {
      return this.viewRaw.rules?.records?.length ?? 0;
    },
    totalEmailRulesCount() {
      return this.viewRaw.rules?.emails?.length ?? 0;
    },
    totalRulesCount() {
      return this.totalDisplayRulesCount + this.totalRecordsRulesCount + this.totalSubmitRulesCount;
    },
    ruleCountMap() {
      return {
        'Actions & Rules': this.totalRulesCount,
        Emails: this.totalEmailRulesCount,
        'Display Rules': this.totalDisplayRulesCount,
        'Submit Rules': this.totalSubmitRulesCount,
        'Record Rules': this.totalRecordsRulesCount,
      };
    },
  },
};
</script>

<style lang="scss">
.toc-links {
  a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #8f9298;
    border-radius: .32em;
    padding: .75em;
    transition: all .1s ease-in;

    .badge {
      margin-left: 8px;
      border-radius: 50em;
      background: #e4e5ea;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 4px;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    &:hover {
      background-color: rgba(97, 102, 104, 0.05);

      svg {
        color: $active;
      }
    }

    p {
      font-size: .96em;
      line-height: 1.25em;
      margin-right: 1em;
      margin-bottom: 0;
    }
  }
  svg {
    width: 22px;
    height: 22px;

    g {
      path:first-child {
        fill: currentColor;
      }
    }
  }
  b {
    color: $active;
    font-weight: 500;
    font-size: 1.1em;
    line-height: 1em;
  }
}
</style>
