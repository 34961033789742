<script setup>
/* eslint-disable no-param-reassign */
import {
  onMounted, onBeforeUnmount, computed, watch, ref, nextTick,
} from 'vue';
import { useStore } from 'vuex';
import Api from '@/lib/api-wrapper';
import checklistStrings from '@/components/checklists/checklist-strings';
import useChecklists from '@/checklists/useChecklists';

const store = useStore();
const highlightedFeatures = computed(() => store.getters['checklists/highlightedFeatures']);

const HIGHLIGHT_SHADOW_CLASS = 'shadow-[0px_0px_1px_5px_rgba(192,_121,_180,_1),0px_0px_2px_8px_rgba(231,_200,_226,_1)]';

const HIGHLIGHTED_ELEMENT_CLASSES = [
  HIGHLIGHT_SHADOW_CLASS,
  '[animation-duration:2s]',
  'animate-pulse',
  'relative',
  'visible',
];

const onFeatureClick = (e) => {
  const clickedElement = e.target;

  const featureElement = clickedElement.dataset.feature
    ? clickedElement
    : clickedElement.closest('[data-feature]');

  if (!featureElement) return;

  store.commit(
    'checklists/setHighlightedFeatures',
    highlightedFeatures.value.filter((f) => f !== featureElement.dataset.feature),
  );
};

const restoreFeature = (featureElement) => {
  const { feature } = featureElement.dataset;
  if (feature && !highlightedFeatures.value.includes(feature)) {
    featureElement.classList.remove(...HIGHLIGHTED_ELEMENT_CLASSES);
    featureElement.removeEventListener('click', onFeatureClick);
  }
};

const highlight = (observer) => {
  observer.disconnect(); // Disconnect the observer before making changes

  // Get all current beacons
  const currentBeacons = document.querySelectorAll('[data-type="beacon"]');

  // Remove beacons that are not in the highlightedFeatures array
  currentBeacons.forEach((beacon) => {
    const featureElement = beacon.closest('[data-feature]');

    beacon.remove();

    if (featureElement) {
      restoreFeature(featureElement);
    }
  });

  // Add new beacons for each feature in the highlightedFeatures array
  highlightedFeatures.value.forEach((feature) => {
    const elements = document.querySelectorAll(`[data-feature="${feature}"]`);
    elements.forEach((div) => {
      if (!div.querySelector('[data-type="beacon"]')) {
        const xOffset = parseInt(div.dataset.featureXOffset ?? '0', 10);
        const yOffset = parseInt(div.dataset.featureYOffset ?? '0', 10);

        const beacon = document.createElement('div');
        beacon.dataset.type = 'beacon';
        beacon.style.top = `${yOffset + 2}px`;
        beacon.style.right = `${xOffset + 2}px`;
        beacon.className = 'absolute flex items-center justify-center translate-x-1/2 -translate-y-1/2 w-12 h-12 overflow-hidden';
        beacon.innerHTML = `
              <div class="animate-ping absolute inline-flex w-6 h-6 rounded-full bg-brand-100"></div>
              <div class="relative inline-flex rounded-full w-6 h-6 bg-brand-500 items-center justify-center shadow-[0px_0px_1px_3px_rgba(231,200,226,1)] z-10">
                <svg viewBox="0 0 16 16" class="icon icon-bulb text-inherit w-4 h-4 mr-0">
                  <use xlink:href="#bulb"></use>
                </svg>
              </div>
            `;
        div.appendChild(beacon);
        div.classList.add(...HIGHLIGHTED_ELEMENT_CLASSES);
        div.addEventListener('click', onFeatureClick);
      }
    });
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  }); // Reconnect the observer after making changes
};

// We do this because the api-wrapper interceptors return directly
// the data property and we have to watch the headers.
function addFirstInterceptor(instance, interceptor) {
  const interceptors = instance.interceptors.response.handlers.slice();

  instance.interceptors.response.handlers = [];
  const id = instance.interceptors.response.use(interceptor);

  interceptors.filter((int) => !!int).forEach(({ fulfilled, rejected }) => {
    instance.interceptors.response.use(fulfilled, rejected);
  });

  return id;
}

const observer = ref(new MutationObserver(() => highlight(observer.value)));

const { totalCompleted, totalCount } = useChecklists();

onMounted(() => {
  // Observe for changes in the DOM
  observer.value.observe(document.body, {
    childList: true,
    subtree: true,
  });

  // Initial addition
  highlight(observer.value);

  const interceptorId = addFirstInterceptor(Api.axios, (response) => {
    if (response.headers?.['knack-checklist-step']) {
      store.dispatch('checklists/fetchSteps');

      response.headers?.['knack-checklist-step'].split(',').forEach((step) => {
        let message = `🎉 You've completed the onboarding task ${checklistStrings.steps[step]}.`;
        let bold = 'See your next task';

        if (step === 'create_account') {
          message = "🎉 Congratulations, you've created an account with Knack!";
          bold = 'See what\'s next in your onboarding guide';
        } else if (totalCompleted.value === totalCount.value - 1) {
          message = `🎉 You've completed the onboarding task ${checklistStrings.steps[step]}`;
          bold = undefined;
        }

        store.dispatch('toasts/addToast', {
          message,
          bold,
          type: 'success',
          onClick: () => {
            store.commit('checklists/setPopoverOpen', true);
          },
          timeout: 8000,
        });
      });
    }

    return response;
  });

  onBeforeUnmount(() => {
    observer.value.disconnect();

    Api.axios.interceptors.response.eject(interceptorId);
  });
});

watch(highlightedFeatures, async () => {
  highlight(observer.value);
});

</script>

<template>
  <div />
</template>
