import AppLoading from '@/components/AppLoading';
import Builder from '@/components/Builder';
import Home from '@/components/Home';
import ApplicationError from '@/components/user/ApplicationError';
import ApplicationNotFound from '@/components/user/ApplicationNotFound';
import ApplicationMaintenance from '@/components/user/ApplicationMaintenance';
import ForgotPassword from '@/components/user/ForgotPassword';
import Logout from '@/components/user/Logout';

import PagesRoutes from '@/router/pages';
import TaskRoutes from '@/router/tasks';
import RecordRoutes from '@/router/records';
import SchemaRoutes from '@/router/schema';
import SettingsRoutes from '@/router/settings';

export default [
  {
    path: '/',
    component: Builder,
    redirect: () => '/schema',
  },
  {
    path: '/loading',
    component: AppLoading,
  },
  {
    path: '/home',
    component: Builder,
    children: [
      {
        path: '',
        component: Home,
      },
    ],
  },
  ...PagesRoutes,
  ...TaskRoutes,
  ...RecordRoutes,
  ...SettingsRoutes,
  ...SchemaRoutes,
  {
    name: 'logout',
    path: '/logout',
    component: Logout,
    props: true,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: 'forgot',
    path: '/forgot-password',
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: 'application error',
    path: '/app-error',
    component: ApplicationError,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: 'application not found',
    path: '/app-not-found',
    component: ApplicationNotFound,
    meta: {
      requiresAuth: false,
    },
  },
  {
    name: 'application maintenance',
    path: '/app-maintenance',
    component: ApplicationMaintenance,
    meta: {
      requiresAuth: false,
    },
  },
];
