import Joi from '@hapi/joi';
import { validate } from './validation-helper';

const urlIsValidRegex = new RegExp(
  '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
  'i',
);
const alphaNumPlusRegex = /^[a-zA-Z0-9-_]*$/;
const certIsValidRegex = /^(\*+|-----(.|\n)+-----(.|\n)+-----(.|\n)+-----)$/;

const requiredJoi = Joi.string().required();
const requiredMessage = '<%= label %> is required.';
const requiredTypeMap = {
  'any.empty': requiredMessage,
};

const urlJoi = Joi.string().regex(urlIsValidRegex, { name: 'urlIsValid' }).required();
const urlMessage = '<%= label %> must be a valid URL.';
const urlTypeMap = {
  'any.empty': requiredMessage,
  'regex.urlIsValid': urlMessage,
};

/**
 * Gets the joi validation schema and error map for Facebook SSO.
 *
 * @returns {{errorMap: object, validationSchema: object}}
 */
export function getFacebookValidation() {
  const validationSchema = Joi.object({
    app_id: requiredJoi,
    app_secret: requiredJoi,
  });

  const errorMap = {
    app_id: { label: 'App ID', types: requiredTypeMap },
    app_secret: { label: 'App Secret', types: requiredTypeMap },
  };

  return {
    validationSchema,
    errorMap,
  };
}

/**
 * Gets the joi validation schema and error map for Google SSO.
 *
 * @returns {{errorMap: object, validationSchema: object}}
 */
export function getGoogleValidation() {
  const validationSchema = Joi.object({
    client_id: requiredJoi,
    client_secret: requiredJoi,
  });

  const errorMap = {
    client_id: { label: 'Client ID', types: requiredTypeMap },
    client_secret: { label: 'Client Secret', types: requiredTypeMap },
  };

  return {
    validationSchema,
    errorMap,
  };
}

/**
 * Gets the joi validation schema and error map for Twitter SSO.
 *
 * @returns {{errorMap: object, validationSchema: object}}
 */
export function getTwitterValidation() {
  const validationSchema = Joi.object({
    app_id: requiredJoi,
    app_secret: requiredJoi,
  });

  const errorMap = {
    app_id: { label: 'API Key', types: requiredTypeMap },
    app_secret: { label: 'API Secret', types: requiredTypeMap },
  };

  return {
    validationSchema,
    errorMap,
  };
}

/**
 * Gets the joi validation schema and error map for OAuth 1 SSO.
 *
 * @returns {{errorMap: object, validationSchema: object}}
 */
export function getOauth1Validation() {
  const validationSchema = Joi.object({
    name: requiredJoi,
    consumer_key: requiredJoi,
    consumer_secret: requiredJoi,
    id_property: requiredJoi,
    access_token_url: urlJoi,
    profile_url: urlJoi,
    request_token_url: urlJoi,
    user_authorization_url: urlJoi,
  });

  const errorMap = {
    name: { label: 'Provider Name', types: requiredTypeMap },
    consumer_key: { label: 'Consumer Key', types: requiredTypeMap },
    consumer_secret: { label: 'Consumer Secret', types: requiredTypeMap },
    id_property: { label: 'ID Property', types: requiredTypeMap },
    access_token_url: { label: 'Access URL', types: urlTypeMap },
    profile_url: { label: 'Profile URL', types: urlTypeMap },
    request_token_url: { label: 'Request URL', types: urlTypeMap },
    user_authorization_url: { label: 'User Authorization URL', types: urlTypeMap },
  };

  return {
    validationSchema,
    errorMap,
  };
}

/**
 * Gets the joi validation schema and error map for OAuth 2 SSO.
 *
 * @returns {{errorMap: object, validationSchema: object}}
 */
export function getOauth2Validation() {
  const validationSchema = Joi.object({
    name: requiredJoi,
    client_id: requiredJoi,
    client_secret: requiredJoi,
    id_property: requiredJoi,
    token_url: urlJoi,
    profile_url: urlJoi,
    authorization_url: urlJoi,
  });

  const errorMap = {
    name: { label: 'Provider Name', types: requiredTypeMap },
    client_id: { label: 'Client ID', types: requiredTypeMap },
    client_secret: { label: 'Client Secret', types: requiredTypeMap },
    id_property: { label: 'ID Property', types: requiredTypeMap },
    token_url: { label: 'Access Token URL', types: urlTypeMap },
    profile_url: { label: 'Profile URL', types: urlTypeMap },
    authorization_url: { label: 'Authorization URL', types: urlTypeMap },
  };

  return {
    validationSchema,
    errorMap,
  };
}

/**
 * Gets the joi validation schema and error map for SAML SSO.
 *
 * @returns {{errorMap: object, validationSchema: object}}
 */
export function getSamlValidation() {
  const certJoi = Joi
    .string()
    .regex(certIsValidRegex, { name: 'certIsValid' })
    .allow('');

  const certMessage = '<%= label %> must contain a certificate with valid headers and footers. For example: "-----BEGIN RSA PRIVATE KEY-----" and "-----END RSA PRIVATE KEY-----", "-----BEGIN CERTIFICATE-----" and "-----END CERTIFICATE-----", etc.';
  const certTypeMap = {
    'regex.certIsValid': certMessage,
  };

  const validationSchema = Joi.object({
    name: requiredJoi,
    issuer: requiredJoi,
    id_property: requiredJoi,
    entry_point: urlJoi,
    logouturl: urlJoi.allow(''),
    cert: certJoi,
    private_cert: certJoi,
    decryption_private_key: certJoi,
    decryption_public_key: certJoi,
  });

  const errorMap = {
    name: { label: 'Provider Name', types: requiredTypeMap },
    issuer: { label: 'Issuer', types: requiredTypeMap },
    id_property: { label: 'ID Property', types: requiredTypeMap },
    entry_point: { label: 'Provider Entry Point', types: urlTypeMap },
    logouturl: { label: 'Logout URL', types: urlTypeMap },
    cert: { label: 'Identity Provider\'s certificate', types: certTypeMap },
    private_cert: { label: 'Private signing certificate', types: certTypeMap },
    decryption_private_key: { label: 'Decryption private certificate', types: certTypeMap },
    decryption_public_key: { label: 'Decryption public certificate', types: certTypeMap },
  };

  return {
    validationSchema,
    errorMap,
  };
}

/**
 * Validates the provider name for SSO.
 * This is performed separately from the other validation because names are used as
 * keys which will cause javascript errors if not checked first.
 *
 * @param {object} toValidate
 * @returns {{errors: object[]}}
 */
export function validateSSOProviderName(toValidate) {
  const nameJoi = Joi.string()
    .regex(alphaNumPlusRegex, { name: 'alphaNumPlus' })
    .required();

  const validationSchema = {
    name: nameJoi,
  };

  const errorMap = {
    name: {
      label: 'Provider Name',
      types: {
        'any.empty': '<%= label %> is required.',
        'regex.alphaNumPlus': '<%= label %> can only contain letters, numbers, dash, and underscore.',
      },
    },
  };

  return validate(toValidate, validationSchema, errorMap);
}
