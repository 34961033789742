<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Icon from '@/components/ui/Icon.vue';
import checklistStrings from './checklist-strings';
import { completeChecklistStep, replaceUrlVariables } from '@/checklists/helpers';

const props = defineProps({
  step: {
    required: true,
    type: Object,
  },
  isLocked: Boolean,
});

const shouldShowVideo = ref(false);
const router = useRouter();
const store = useStore();

function toggleVideo() {
  shouldShowVideo.value = !shouldShowVideo.value;
}

const isCompleted = computed(() => !!props.step.completed_at);

const actionIconType = computed(() => {
  if (props.step.definition.type === 'in-product' || props.step.definition.type === 'documentation') {
    return 'arrow-right';
  }

  if (props.step.definition.type === 'video') {
    return 'play';
  }

  return null;
});

const iconType = computed(() => {
  if (isCompleted.value) return 'check-circle';
  if (props.isLocked) return 'lock';
  return 'circle';
});

const iconClass = computed(() => {
  if (isCompleted.value) return 'w-5 h-5 justify-c fill-[url(#svg-gradient-2)]';
  if (props.isLocked) return 'w-4 h-4 justify-c text-muted';
  return 'w-5 h-5 justify-c fill-[url(#svg-gradient-1)]';
});

const onClick = () => {
  if (props.isLocked) return;

  if (props.step.definition.type === 'video') {
    toggleVideo();
  } else if (props.step.definition.type === 'documentation') {
    completeChecklistStep(props.step.definition.id);

    window.open(props.step.definition.extra.url, '_blank');
  } else if (props.step.definition.type === 'in-product') {
    const [area, url, highlight] = props.step.definition.extra.link.split('::');

    if (area === 'builder') {
      if (highlight) {
        store.commit('checklists/setHighlightedFeatures', highlight.split(','));
      }

      if (url === '{current}') return;

      const replacedUrl = replaceUrlVariables(url, store);

      if (replacedUrl) router.push(`/${replacedUrl}`);
    } else if (area === 'dashboard') {
      window.location.href = `${process.env.VUE_APP_DASHBOARD_URL || '/'}?checklist_step=${props.step.definition.id}`;
    }
  }
};

const onOpenVideo = () => {
  completeChecklistStep(props.step.definition.id);
};
</script>

<template>
  <div>
    <div
      :class="[
        'group flex gap-3 rounded p-2 text-start transition-colors',
        {
          'items-center': !step.definition.description,
          'bg-default hover:bg-muted cursor-pointer': !isCompleted && !isLocked
        },
      ]"
      :data-step-id="step.definition.id"
      @click="onClick"
    >
      <Icon
        :type="iconType"
        :class="iconClass"
      />
      <div class="flex flex-1 flex-col gap-1">
        <div
          :class="[
            'text-sm text-emphasis',
            {
              'text-brand-500': isCompleted,
              'text-subtle': isLocked,
            },
          ]"
        >
          {{ checklistStrings.steps[step.definition.id] }}
        </div>
        <div
          v-if="(step.definition.description || isLocked) && !isCompleted"
          class="text-xs"
          :class="{
            'text-subtle': !isLocked,
            'text-muted': isLocked
          }"
        >
          {{ isLocked
            ? checklistStrings.steps[`${step.definition.blocked_by}_locked`]
            : checklistStrings.steps[`${step.definition.id}_description`] }}
        </div>
      </div>
      <div
        :class="[
          'self-center rounded p-1 transition-colors leading-none',
          {
            'group-hover:bg-subtle': !isCompleted && !isLocked && actionIconType,
            'opacity-50': isLocked,
          },
        ]"
      >
        <a
          v-if="isCompleted"
          :tabindex="-1"
          :href="step.definition.article_url"
          target="_blank"
          rel="noreferrer"
          class="hover:bg-transparent p-0"
        >
          <div class="text-emphasis underline text-xs font-normal">
            Learn more
          </div>
        </a>
        <template v-else>
          <Icon
            :type="actionIconType"
            class="w-4 h-4 fill-[url(#svg-gradient-1)]"
          />
        </template>
      </div>
    </div>

    <div
      v-if="shouldShowVideo"
      class="relative flex min-h-[220px] cursor-pointer items-center justify-center rounded bg-muted p-3 transition-colors hover:bg-subtle"
      @click="onOpenVideo"
    >
      <div class="absolute right-2 top-2 flex gap-1 text-default">
        <Icon
          class="h-4 w-4"
          type="full-screen"
        />
        <Icon
          class="h-4 w-4"
          type="close"
          @click="toggleVideo"
        />
      </div>
      <Icon
        type="play"
        class="w-11 h-11 fill-[url(#svg-gradient-1)]"
      />
    </div>
  </div>
</template>
