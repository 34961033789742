<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { getBuilderNextBaseUrl } from '@/lib/url-helper';
import { ADD_IMPORT_V1 } from '@/constants/featureflags';
import Icon from '@/components/ui/Icon';
import EmptyStateGeneric from '@/components/ui/EmptyStateGeneric';
import BuilderButton from '@/components/ui/BuilderButton';
import { isTrial } from '@/lib/plan-helper';

const router = useRouter();
const store = useStore();

const handleAddTableButtonClick = () => (store.getters.hasFeature(ADD_IMPORT_V1) && !isTrial() ? window.location.replace(`${getBuilderNextBaseUrl()}/tables/add`) : router.push('/schema/list/objects/add/import'));
</script>

<template>
  <EmptyStateGeneric top>
    <div class="mb-2">
      <Icon
        type="table-cells"
        class="h-auto w-[110px] fill-[url(#svg-brand-gradient)] opacity-40"
      />
    </div>

    <h2 class="empty-state__title margin-bottom-lg text-xl text-emphasis font-medium mb-2">
      Let's build your first table
    </h2>

    <p class="empty-state__paragraph text-default mb-6">
      Start by creating a blank table, importing a spreadsheet, or choosing from our table catalog
    </p>

    <BuilderButton
      data-feature="add_table_button"
      data-feature-x-offset="-26"
      data-feature-y-offset="-26"
      theme="confirm"
      size="lg"
      class="mb-6"
      data-cy="add-first-object"
      @click="handleAddTableButtonClick"
    >
      <icon type="add" /> Add a Table
    </BuilderButton>

    <p class="mb-0">
      <a
        href="https://learn.knack.com/article/ejdb2toq4i-import-records"
        target="_blank"
        class="flex items-center justify-center underline text-default text-base font-medium"
      >
        Learn more about importing
        <Icon
          type="open-in-new"
          class="link-with-icon__icon ml-2 text-emphasis"
        />
      </a>
    </p>
  </EmptyStateGeneric>
</template>
