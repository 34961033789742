export default {
  sections: {
    basics: 'Knack Basics',
    build_app: 'Build Your App',
    explore: 'Explore Knack',
    ai: 'Harness the Power of Knack AI',
  },
  steps: {
    create_account: 'Create an account',
    know_knack: 'Get to Know the 3 Main Areas of Knack',
    know_knack_description: 'Learn about the Dashboard, Builder and Live App',
    create_app: 'Head over to your Account Dashboard and build an app',
    create_app_description: 'This is where where you can create and see all your apps',
    create_app_locked: 'Create an app first!',
    create_table: 'Create a new Table',
    create_table_description: 'Tables are where your data is stored, and can be created from scratch, via CSV, or Google Sheets',
    create_table_locked: 'Create a table first!',
    create_short_text_field: 'Add a Short Text field to your table',
    create_short_text_field_description: 'These fields are perfect for text characters which do not require any special formatting (e.g. for titles, names etc.)',
    add_records: 'Backfill your table with Records',
    add_records_description: 'As the app builder, you can populate your table with records. Records are rows in your table that can be displayed and interacted with by Live App users',
    build_page: 'Build a Page',
    build_page_description: 'Pages are how users interact with your tables and data',
    build_page_locked: 'Build a page first!',
    add_form_view: 'Add a Form View to your page',
    add_form_view_description: 'Forms allow your users to add or edit records',
    add_table_view: 'Add a Grid View to your page',
    add_table_view_description: 'Grids are a way to display records to your users',
    add_table_view_locked: 'Add a grid view first!',
    add_action_link_to_grid: 'Add an Action Link to your grid',
    add_action_link_to_grid_description: 'Grids can include action links that allow users to access more details, edit, or delete individual records displayed',
    preview_page: 'Preview your Page',
    preview_page_description: 'See a preview of how end-users will experience your page',
    customize_roles: 'Customize Roles for your Live App users',
    customize_roles_description: 'Update the name of your first User Role. Add more with the “+” button',
    customize_roles_locked: 'Customize user roles first!',
    create_app_user: 'Add your first Live App user',
    create_app_user_description: "Knack's user features allow you to require Live App users to login before they can access the Live App",
    customize_design: 'Customise the Look & Feel of your app',
    open_live_app: 'Open up the Live App to experience what your end-users will see!',
    explore_suggested_app: 'Explore a suggested Template App',
    explore_suggested_app_description: 'Create an app through our marketplace and discover more opportunities',
    api_js_css: 'Explore Knack’s API, Javascript and CSS area',
    add_payments: 'Explore linking Stripe or Paypal to accept Payments',
    invite_shared_builder: 'Invite a Shared Builder to collaborate on building your app!',
    create_app_from_ai: 'Get started with AI',
  },
} as Record<string, Record<string, string>>;
