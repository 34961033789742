<template>
  <div class="body-wrapper m-0 px-6 py-2 max-w-xl">
    <Modal
      v-if="showMapsUsageModal"
      title="Maps Usage Summary"
      @close="showMapsUsageModal = false"
    >
      <p><strong>Map View Loads</strong> and <strong>Geocoding of Records</strong> figures below can be used to estimate monthly costs if you'd like to use Google Maps as your Maps Provider.</p>
      <p>
        <strong>Map View Loads</strong><br>
        Lifetime monthly average as of {{ currentDate }}: {{ mapsUsageResults.map_loads.monthly_average }}
      </p>
      <div
        v-if="showMapsViewsUsage"
        class="kn-table kn-table-wrapper"
      >
        <table
          id="map-views-usage-table"
          class="is-bordered is-striped"
        >
          <thead>
            <th>
              <span class="table-fixed-label">
                <a
                  address="true"
                  class="kn-sort text-default font-medium"
                >
                  View ID
                </a>
              </span>
            </th>
            <th>
              <span class="table-fixed-label">
                <a
                  address="true"
                  class="kn-sort text-default font-medium"
                >
                  Lifetime Map Load Count
                </a>
              </span>
            </th>
          </thead>
          <tbody>
            <tr v-for="{scene_key, view_key, count} in mapsUsageResults.map_loads.views">
              <td>
                <RouterLink
                  :to="`/pages/${scene_key}`"
                  target="_blank"
                >
                  {{ view_key }}
                </RouterLink>
              </td>
              <td>{{ count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <strong>Geocoding of Records</strong><br>
        Estimated number of monthly geocode requests: {{ mapsUsageResults.total_existing_geocode_count }}
      </p>
      <div
        v-if="showMapsGeocodeUsage"
        class="kn-table kn-table-wrapper mb-0"
      >
        <table
          id="maps-geocode-usage-table"
          class="is-bordered is-striped"
        >
          <thead>
            <th>
              <span class="table-fixed-label">
                <a
                  address="true"
                  class="kn-sort text-default font-medium"
                >
                  Object ID
                </a>
              </span>
            </th>
            <th>
              <span class="table-fixed-label">
                <a
                  address="true"
                  class="kn-sort text-default font-medium"
                >
                  No. of Geocoded Address Fields
                </a>
              </span>
            </th>
            <th>
              <span class="table-fixed-label">
                <a
                  address="true"
                  class="kn-sort text-default font-medium"
                >
                  x
                </a>
              </span>
            </th>
            <th>
              <span class="table-fixed-label">
                <a
                  address="true"
                  class="kn-sort text-default font-medium"
                >
                  Record Count
                </a>
              </span>
            </th>
            <th>
              <span class="table-fixed-label">
                <a
                  address="true"
                  class="kn-sort text-default font-medium"
                >
                  =
                </a>
              </span>
            </th>
            <th>
              <span class="table-fixed-label">
                <a
                  address="true"
                  class="kn-sort text-default font-medium"
                >
                  Total Count
                </a>
              </span>
            </th>
          </thead>
          <tbody>
            <tr v-for="{key, address_field_count, record_count, existing_geocode_count} in mapsUsageResults.objects">
              <td>{{ key }}</td>
              <td>{{ address_field_count }}</td>
              <td>x</td>
              <td>{{ record_count }}</td>
              <td>=</td>
              <td>{{ existing_geocode_count }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Modal>

    <div>
      <div class="flex gap-2">
        <p class="btn light-gray small text-default font-medium">
          Your Map Provider
        </p>
        <IfPlan :is-starter="true">
          <div class="mb-2">
            <UpgradeButton />
          </div>
        </IfPlan>
      </div>
      <p>
        Select your provider to display map elements and geolocate address fields
      </p>
    </div>

    <div class="flex gap-6 mt-8">
      <label
        v-for="mapProvider in mapProviders"
        :key="mapProvider.value"
        class="flex m-0 text-emphasis text-base font-normal leading-4"
      >
        <input
          v-model="localApp.settings.mapsAndGeocoderProvider"
          class="m-0"
          type="radio"
          name="dateFormat"
          :disabled="isStarter()"
          :value="mapProvider.value"
          @change="changeProvider"
        >
        <span>
          {{ mapProvider.label }}
        </span>
      </label>
    </div>

    <form
      class="form-large mt-8"
      @submit="onSubmitMapsSettings"
    >
      <div
        v-if="isGoogleMaps && !isStarter()"
        class="view-input"
      >
        <label for="api_key mb-2">Google Maps API Key</label>
        <div v-if="showMapsApiKeyInput">
          <input
            name="api_key"
            type="text"
            @blur="onBlurGoogleApiKey"
          >
          <p class="instructions not-italic text-xs">
            Enter your Google Maps API Key
          </p>
        </div>
        <div v-if="showGoogleApiKey">
          <div class="relative mb-2">
            <input
              name="api_key"
              :type="showUnhashedGoogleApiKey ? 'text': 'password'"
              disabled="disabled"
              :value="unhashedGoogleApiKey ? unhashedGoogleApiKey : placeholderKey"
              class="disabled"
            >
            <span class="absolute inset-y-3 flex cursor-pointer items-center right-3">
              <Icon
                class="h-6 w-6"
                :type="showUnhashedGoogleApiKey ? 'eye' : 'eye-slash'"
                @click="handleToggleApiKeyVisibility"
              />
            </span>
          </div>
          <p>
            <a
              class="btn light-gray small underline"
              @click="onClickRemoveApiKey"
            >Remove Key</a>
          </p>
        </div>
        <p
          v-if="hasAPIKeyError"
          class="error-alert form-errors rounded-lg p-4 bg-destructive mb-6 border-0 text-destructive-emphasis text-base"
        >
          The Google Maps API key is invalid. Please review that the key is correct and has Google Geocoding API enabled.
        </p>
        <label for="map_id mb-2">Google Map ID</label>
        <div>
          <input
            v-model="localApp.settings.googleMapId"
            name="map_id"
            type="text"
          >
          <p class="instructions not-italic text-xs">
            Enter Google Map ID to link your specific map data
          </p>
        </div>
        <p
          v-if="hasMapIdError"
          class="error-alert form-errors rounded-lg p-4 bg-destructive mb-6 border-0 text-destructive-emphasis text-base"
        >
          Google Map ID required
        </p>
        <p
          v-if="showMapsApiKeyInput"
          class="error-alert border-0 rounded-lg bg-warning text-base text-warning-emphasis mt-8"
        >
          Maps and address geolocation will use the Google account you provide. You will be responsible for adhering to <a
            href="https://cloud.google.com/maps-platform/terms/maps-service-terms"
            target="_blank"
          >Google’s terms of service</a> and any incremental costs related to usage.
        </p>
      </div>

      <div class="mt-8">
        <p class="text-emphasis text-base">
          <IfPlan :is-starter="false">
            <a
              class="btn light-gray small text-default font-medium underline"
              @click="onClickMapsUsage"
            >
              View Map Usage
            </a>
          </IfPlan>
          <IfPlan :is-starter="true">
            <p class=" text-[#a9a2a8] small font-medium underline">
              View Map Usage
            </p>
          </IfPlan>
        </p>
        <p>
          See a summary of your map views, including number of fields and a count of views
        </p>
      </div>

      <div class="flex mt-8">
        <IfPlan :is-starter="false">
          <a
            class="button medium save bg-gradient-primary rounded-lg border-0 p-3 text-base leading-4 font-medium ml-auto"
            @click="onSubmitMapsSettings"
          >Save Settings</a>
        </IfPlan>
        <IfPlan :is-starter="true">
          <p
            class="button medium save bg-gradient-primary rounded-lg border-0 p-3 text-base leading-4 font-medium ml-auto opacity-50"
          >
            Save Settings
          </p>
        </IfPlan>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import $ from 'jquery';
import moment from 'moment-timezone';

import Modal from '@/components/ui/Modal';
import RequestUtils from '@/components/util/RequestUtils';
import { isStarter } from '@/lib/plan-helper';
import Icon from '@/components/ui/Icon.vue';
import Api from '@/lib/api-wrapper';
import UpgradeButton from '@/components/ui/UpgradeButton.vue';
import IfPlan from '@/components/util/IfPlan';

export default {
  name: 'MapProvider',
  components: {
    IfPlan,
    UpgradeButton,
    Icon,
    Modal,
  },
  mixins: [
    RequestUtils,
  ],
  data() {
    const localApp = cloneDeep(this.$store.getters.app.raw());

    if (isNil(localApp.settings.mapsAndGeocoderProvider)) {
      localApp.settings.mapsAndGeocoderProvider = 'here';
    }

    return {
      localApp,
      hasSaved: false,
      clearApiKey: false,
      hasAPIKeyError: false,
      hasMapIdError: false,
      showMapsUsageModal: false,
      mapsUsageResults: {
        map_loads: {},
        objects: [],
      },
      currentDate: moment().format('M/D/YYYY'),
      previousGoogleKey: null,
      previousGoogleMapId: null,
      mapProviders: [
        {
          value: 'here',
          label: 'Knack Maps',
        },
        {
          value: 'google',
          label: 'Google Maps',
        },
      ],
      showUnhashedGoogleApiKey: false,
      unhashedGoogleApiKey: null,
    };
  },
  computed: {
    ...mapGetters([
      'app',
    ]),
    isGoogleMaps() {
      return this.localApp.settings.mapsAndGeocoderProvider === 'google';
    },
    showMapsApiKeyInput() {
      if (this.hasUnhashedAndSavedGoogleApiKey || this.hasHashedGoogleApiKey) {
        return false;
      }

      if (this.clearApiKey) {
        return true;
      }

      if (!this.hasSaved) {
        return true;
      }

      return false;
    },
    showGoogleApiKey() {
      return this.hasUnhashedAndSavedGoogleApiKey || this.hasHashedGoogleApiKey;
    },
    placeholderKey() {
        return [...Array(39)].map(() => `*`).join(``) // eslint-disable-line
    },
    showMapsViewsUsage() {
      return !isEmpty(this.mapsUsageResults.map_loads.views);
    },
    showMapsGeocodeUsage() {
      return this.mapsUsageResults.total_existing_geocode_count;
    },
    hasUnhashedAndSavedGoogleApiKey() {
      return this.unhashedGoogleApiKey && this.hasSaved;
    },
    hasHashedGoogleApiKey() {
      return this.localApp.settings.googleMapsApiKey && this.localApp.settings.googleMapsApiKey.match(/^[0-9a-f]{24}$/);
    },
  },
  watch: {
    'localApp.settings.googleMapId': function (newValue) {
      if (this.hasMapIdError && newValue) {
        this.hasMapIdError = false;
      }
    },
  },
  created() {
    this.notifyErrors = true;
  },
  methods: {
    isStarter,
    onClickMapsUsage() {
      this.commitRequest({
        request: () => window.Knack.Api.getApplicationMapsUsage(),
        onSuccess: (usageResults) => {
          this.mapsUsageResults = usageResults;

          this.showMapsUsageModal = true;
        },
      });
    },
    onSubmitMapsSettings() {
      this.updateMapsSettings();
    },
    changeProvider(event) {
      this.hasAPIKeyError = false;
      this.hasMapIdError = false;

      if (event.target.value === 'here') {
        if (!isNil(this.localApp.settings.googleMapsApiKey)) {
          this.previousGoogleKey = this.localApp.settings.googleMapsApiKey;
          this.previousGoogleMapId = this.localApp.settings.googleMapId;

          this.localApp.settings.googleMapsApiKey = null;
          this.localApp.settings.googleMapId = null;
        }
      } else if (this.previousGoogleKey) {
        this.localApp.settings.googleMapsApiKey = this.previousGoogleKey;
        this.localApp.settings.googleMapId = this.previousGoogleMapId;
      }
    },
    async updateMapsSettings() {
      this.hasAPIKeyError = false;
      this.hasMapIdError = false;

      if (this.isGoogleMaps && isNil(this.localApp.settings.googleMapsApiKey)) {
        this.hasAPIKeyError = true;

        return;
      }

      if (this.isGoogleMaps && (this.localApp.settings.googleMapsApiKey || !this.showMapsApiKeyInput) && isNil(this.localApp.settings.googleMapId) && !this.clearApiKey) {
        this.hasMapIdError = true;

        return;
      }

      return this.commitRequest({
        validate: () => this.app.validate(this.localApp),
        request: () => this.app.update({
          settings: this.localApp.settings,
        }),
        onSuccess: () => {
          if (!isNil(this.localApp.settings.googleMapsApiKey)) {
            this.hasSaved = true;
          }
        },
        onError: (errors) => {
          if (errors.some((err) => isString(err.message) && err.message.includes('invalid api key'))) {
            $('input[name=api_key]').focus();

            this.hasAPIKeyError = true;
          }
        },
      });
    },
    async onClickRemoveApiKey(event) {
      event.preventDefault();

      this.unhashedGoogleApiKey = null;
      this.localApp.settings.googleMapsApiKey = '';

      await this.updateMapsSettings();

      this.clearApiKey = true;
      this.hasMapIdError = false;
    },
    getGoogleMapsApiKeyFromHash() {
      this.commitRequest({
        request: () => {
          const googleMapsApiKeyHash = this.localApp.settings.googleMapsApiKey;
          const options = {
            url: `credentials/${googleMapsApiKeyHash}`,
            method: 'GET',
            encoding: null,
          };
          return Api.axios(options);
        },
        onSuccess: (result) => {
          this.unhashedGoogleApiKey = result.key;
        },
      });
    },
    handleToggleApiKeyVisibility() {
      if (!this.unhashedGoogleApiKey) {
        this.getGoogleMapsApiKeyFromHash();
      }
      this.showUnhashedGoogleApiKey = !this.showUnhashedGoogleApiKey;
    },
    onBlurGoogleApiKey(event) {
      const { value } = event.target;

      this.unhashedGoogleApiKey = value;
      this.localApp.settings.googleMapsApiKey = value;
      this.hasSaved = false;
    },
  },
};
</script>

<style scoped lang="scss">
.kn-table-wrapper > table {
  width: 100%;

  td,
  th {
    border: 1px solid transparent;
    border-bottom-color: #dbdbdb;
    padding: 6px 8px;
    vertical-align: bottom;
    white-space: normal;
    text-align: left;
    position: relative;
  }
  thead {
    transition: opacity 0.1s ease-in;
    tr:hover {
      background-color: inherit;
    }
    td,
    th {
      border-bottom-width: 2px;
    }
  }
  tbody {
    tr {
      &:last-child {
        td,
        th {
          border-bottom-width: 0;
        }
      }
    }
  }
  tfoot {
    td,
    th {
      border-width: 2px 0 0;
    }
  }
  // Modifiers
  &.is-bordered {
    td,
    th,
    .kn-item {
      border-width: 1px;
      border-color: #dbdbdb;
    }
    th {
      background-color: #ecedef;
    }
    tr:last-child {
      td,
      th,
      .kn-item {
        border-bottom-width: 1px;
      }
    }
  }

  &.is-striped {
    tbody tr:nth-child(even) {
      background-color: #fafafa;
    }
  }
}
</style>
