const storeState = {
  schema: '',
  records: '',
  pages: '',
  tasks: '',
  settings: '',
  queries: {
    global: '',
    schema: '',
    records: '',
    pages: '',
    tasks: '',
    settings: '',
  },
};

const parseQueryString = (str) => {
  if (_.isEmpty(str)) {
    return {};
  }

  const string = `{"${str.replace(/&/g, '","').replace(/=/g, '":"')}"}`;

  return JSON.parse(string, (key, value) => (key === '' ? value : decodeURIComponent(value)));
};

const writeQueryString = (obj) => Object.keys(obj).map((key) => `${key}=${encodeURIComponent(obj[key])}`).join('&');

const storeGetters = {
  getRoute: (state) => (routeType) => state[routeType],
  getQuery: (state) => (routeType) => {
    const query = _.compact([
      state.queries.global,
      state.queries[routeType],
    ]).join('&');

    return `${query}`;
  },
  getRouteWithQuery: (state, getters) => (routeType) => {
    const route = getters.getRoute(routeType);
    const query = getters.getQuery(routeType);

    return `${route}${_.isEmpty(query) ? '' : `?${query}`}`;
  },
};

const storeMutations = {
  updateRoute(state, { routeType, routeValue }) {
    state[routeType] = routeValue;
  },
  updateQuery(state, { queryType, queryKey, queryValue }) {
    const queries = parseQueryString(state.queries[queryType]);

    queries[queryKey] = queryValue;

    state.queries[queryType] = writeQueryString(queries);
  },
  resetUserObjectRoutes(state, userObjectKey) {
    if (state.schema.includes(userObjectKey)) {
      state.schema = '';
    }

    if (state.records.includes(userObjectKey)) {
      state.records = '';
    }
  },
  resetAllRoutes(state) {
    state.schema = '';
    state.records = '';
    state.pages = '';
    state.tasks = '';
    state.settings = '';
  },
  resetRoutes(state, key) {
    if (state.schema.includes(key)) {
      state.schema = '';
    }

    if (state.records.includes(key)) {
      state.records = '';
    }

    if (state.pages.includes(key)) {
      state.pages = '';
    }

    if (state.tasks.includes(key)) {
      state.tasks = '';
    }
  },
  resetQueries({ queries: queryTypes }, key) {
    Object.keys(queryTypes).map((queryType) => {
      let queries = parseQueryString(queryTypes[queryType]);

      if (key in queries) {
        queries = _.omit(queries, key);
        queryTypes[queryType] = writeQueryString(queries);
      }
    });
  },
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations: storeMutations,
};
