<template>
  <div
    ref="wrapper"
    class="kn-table-element"
  >
    <!-- EMPTY -->
    <template v-if="columnsAreEmpty">
      <EmptyFieldsDropZone
        class="drop-target"
        :class="{'is-dragging': isDraggingColumn}"
        buildable="table"
        :build-path="addTableColumnPath"
        :is-dragging-field="isDraggingColumn"
        @dragover="onDragOver"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @drop="onDrop"
      />
    </template>

    <template v-else>
      <table
        ref="table"
        class="rounded-lg"
        :class="tableClass"
      >
        <thead>
          <tr>
            <template v-if="!draggable">
              <th
                v-if="showCheckboxes"
                class="p-0 h-10 bg-gray-50 shadow-[-8px_0px_0px_0px_#FAFAFA] border-l-0"
                style="width: 32px;"
              >
                <span
                  class="inline-flex justify-center h-full bg-subtle rounded-tl-lg items-center w-[34px]"
                >
                  <input
                    v-model="checkboxAllRecords"
                    type="checkbox"
                    @change="onToggleAllCheckboxes($event)"
                  >
                </span>
              </th>
              <th
                v-if="showBuilderLinks"
                style="width: 48px;"
              >
                <span class="inline-flex w-12">
                  &nbsp;
                </span>
              </th>
              <SortableColumn
                v-for="(column, index) in columns"
                :key="index"
                :column="column"
                :index="index"
                :sort="sort"
                :allow-sorting="allowSorting"
                :is-first-column="index === 0 && !showCheckboxes"
                :is-last-column="index === (columns.length - 1)"
                @clickFieldAction="onClickFieldAction"
                @sort="setSort"
              />
            </template>

            <template v-else>
              <DraggableColumn
                v-for="(column, index) in columns"
                :key="index"
                :column="column"
                :index="index"
                :route="`${routePrefix}/columns/${index}`"
                :view="view"
                @delete="onDeleteColumn"
                @activate="onActivateColumn"
                @dragstart="onDragStart"
                @dragend="onDragEnd"
                @dragover="onDragOver"
                @dragenter="onDragEnter"
                @dragleave="onDragLeave"
                @drop="onDrop"
              />
            </template>
          </tr>
        </thead>
        <tbody v-if="records.length">
          <template
            v-for="(record, recordIndex) in records"
            :key="recordIndex"
          >
            <template
              v-for="(group) in rowGroupings"
              :key="`${recordIndex}-${group.columnIndex}`"
            >
              <tr
                v-if="showGroup(record, group.column, group.columnIndex, recordIndex === records.length - 1)"
                class="kn-table-group"
                :class="groupLevelClass(group.columnIndex)"
              >
                <td
                  :colspan="groupColSpan"
                  style="pointer-events: all !important;"
                >
                  <span
                    v-if="record[group.column.field.key]"
                    style="pointer-events: none;"
                  >{{ sanitizedText(record[group.column.field.key]) }}</span>
                  <span
                    v-else-if="recordIndex === 0"
                    style="font-weight: normal;"
                  ><em>{{ group.column.header }} group</em></span>
                  <span v-else>&nbsp;</span>
                  <RouterLink
                    v-if="recordIndex === 0"
                    v-slot="{ isActive, navigate }"
                    :to="`${routePrefix}/columns/${group.columnIndex}`"
                    custom
                  >
                    <div
                      class="item-links gap-1 bg-subtle rounded-t-lg border-b-0 p-1"
                      :class="{'router-link-active': isActive}"
                      style="display: inline-block; position: relative;"
                      @click="navigate"
                    >
                      <a
                        v-tippy
                        content="Edit this grouped column"
                        class="h-6 w-6 hover:bg-emphasis rounded-md inline-flex items-center justify-center m-0"
                        @click.stop="onActivateColumn(group.columnIndex)"
                      >
                        <Icon
                          class="text-default"
                          type="edit"
                        />
                      </a>
                      <a
                        v-tippy
                        content="Delete this grouped column"
                        class="h-6 w-6 hover:bg-emphasis rounded-md inline-flex items-center justify-center m-0"
                        @click.stop="onDeleteColumn(group.columnIndex)"
                      >
                        <Icon
                          class="text-default"
                          type="delete"
                        />
                      </a>
                    </div>
                  </RouterLink>
                </td>
              </tr>
            </template>
            <tr
              :id="record.id"
              data-cy="record-row"
            >
              <td
                v-if="showCheckboxes"
                style="vertical-align: top;"
                nowrap
              >
                <input
                  type="checkbox"
                  :data-id="record.id"
                  :checked="checkedRecordIds.includes(record.id)"
                  @change="onToggleCheckbox($event)"
                >
              </td>
              <td
                v-if="showBuilderLinks"
                style="vertical-align: top;"
              >
                <div class="button-group">
                  <button
                    v-tippy
                    content="Edit Record"
                    type="button"
                    class="button-square expand-record-link rounded bg-transparent hover:bg-brand-50"
                    @click="onClickExpandRecord(record.id)"
                  >
                    <Icon
                      class="text-default"
                      type="edit"
                    />
                  </button>

                  <IfPlan :level-is-minimum-of="2">
                    <button
                      v-tippy
                      content="Record History"
                      type="button"
                      class="button-square expand-record-link rounded bg-transparent hover:bg-brand-50"
                      @click="onClickExpandRecordHistory(record.id)"
                    >
                      <Icon
                        class="text-default"
                        type="history"
                      />
                    </button>
                  </IfPlan>
                </div>
              </td>

              <template v-for="(columnAndField, columnIndex) in columnsWithFields">
                <template v-if="!isGrouping(columnAndField.column)">
                  <td
                    v-if="columnIsLinkType(columnAndField.column.type)"
                    :key="columnIndex"
                    :class="getCellClasses(recordIndex, columnIndex)"
                    :style="getCellStyle(columnAndField.column, passedRules[recordIndex][columnIndex])"
                  >
                    <ViewLink
                      :item-schema="columnAndField.column"
                      :passed-rules="passedRules[recordIndex][columnIndex]"
                      :icon="getCellIcon(columnAndField.column, passedRules[recordIndex][columnIndex])"
                      :link-type="getColumnLinkType(columnAndField.column.type)"
                      :style="getViewLinkStyle(columnAndField.column, passedRules[recordIndex][columnIndex])"
                    >
                      <FieldValueWrapper
                        v-if="columnAndField.column.type === 'link' && columnAndField.column.link_type === 'field'"
                        :index="`${recordIndex}-${columnIndex}-content`"
                        :is-connected-values="recordFieldValueIsShowingConnectedValues(columnAndField)"
                        :field="columnAndField.field"
                        :viewItem="columnAndField.column"
                        :value="getRecordFieldRawValue(columnAndField.column, columnAndField.field, record)"
                        :displayValue="getRecordFieldDisplayValue(columnAndField.column, columnAndField.field, record)"
                        :editable="false"
                      />
                      <template v-else>
                        {{ getViewLinkText(columnAndField.column) }}
                      </template>
                    </ViewLink>
                    <ViewChildLink
                      v-if="isLinkingToChildPage(columnAndField) && recordIndex === 0 && getLinkPageKey(columnAndField)"
                      :tooltip="`Navigate to this page`"
                      top="2px"
                      :url="typeof columnAndField.column.scene === `string` ? `/pages/${getLinkPageKey(columnAndField)}` : ``"
                    />

                  </td>
                  <TableCellNoEdit
                    v-else
                    :id="getTableCellId(recordIndex, columnIndex)"
                    :key="`${columnIndex}-else`"
                    :highlight-cell="shouldHighlightCell(this, recordIndex, columnIndex)"
                    :column="columnAndField.column"
                    :field="columnAndField.field"
                    :index="recordIndex"
                    :passed-rules="passedRules[recordIndex][columnIndex]"
                    :record="record"
                    :force-truncate="forceTruncate"
                    :inline-edit-attachment="columnIndex === 0 ? `start` : `end`"
                    :data-cy="`table-cell-${columnAndField.field ? columnAndField.field.key : `link`}`"
                    :editable="showInlineEdit && tableCellIsEditable(columnAndField)"
                    @click="showEditPopover($event, record, columnAndField.field, columnAndField.column)"
                  />
                </template>
              </template>
            </tr>
          </template>
          <template
            v-if="shouldRenderTotals"
          >
            <ColumnTotals
              v-for="(total, totalIndex) in totals"
              :key="totalIndex"
              :total="total"
              :columns="columns"
              :records="records"
              :show-builder-links="showBuilderLinks"
              :show-checkboxes="showCheckboxes"
            />
          </template>
        </tbody>
        <tbody v-else>
          <tr class="kn-tr-nodata">
            <td
              class="kn-td-nodata"
              :colspan="columnsCount"
            >
              No Data
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <!-- INLINE EDITING -->
    <template v-if="inlineEditTeleportDestination">
      <Teleport :to="inlineEditTeleportDestination">
        <Popover
          ref="popover"
          trigger="manual"
          placement="right"
          :boundary="inlineEditingTarget"
          :open="true"
          :show-arrow="false"
          :distance-offset="-10"
          @hide="onHidePopover"
        >
          <template #content>
            <div
              id="kn-table-cell-popover"
              class="p-4 bg-default -m-2 rounded group/shared"
            >
              <FormWrapper :errors="errors">
                <FormInput
                  :input="inlineEditingField.getAsFormInput()"
                  :field="inlineEditingField"
                  :model-value="inlineEditingLocalValue"
                  :show-label="false"
                  :view-key="inlineEditingColumn.objectKey"
                  :focus="true"
                  :is-in-popover="true"
                  :show-name-field-hint-labels="true"
                  @loaded="onInlineInputLoaded"
                  @update="onInlineInputUpdated"
                />
                <ErrorBanner
                  v-if="errorMessage"
                >
                  <span>{{ errorMessage }}</span>
                </ErrorBanner>
                <SubmitButton
                  :disabled="submitIsDisabled"
                  :is-loading="isRequestPending"
                  class="flex justify-end gap-2"
                  :class="{ disabled: submitIsDisabled }"
                  :focus="true"
                  button-text="Submit"
                  :show-cancel="true"
                  @submit="onSubmitInlineEdit"
                  @cancel="closeInlineEdit"
                />
              </FormWrapper>
            </div>
          </template>
        </Popover>
      </Teleport>
    </template>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import $ from 'jquery';

import { getClassesFromDesignSettings, designSettingsAliases } from '@knack/style-engine';

import Field from '@/store/models/Field';
import RecordModel from '@/store/models/Record';
import { eventBus } from '@/store/bus';

import ColumnTotals from '@/components/renderer/table/ColumnTotals';
import DragDropUtils from '@/components/renderer/mixins/DragDropUtils';
import DraggableColumn from '@/components/renderer/table/DraggableColumn';
import EmptyFieldsDropZone from '@/components/renderer/shared/EmptyFieldsDropZone';
import FieldValueWrapper from '@/components/renderer/records/FieldValueWrapper';
import FormWrapper from '@/components/ui/FormWrapper';
import FormInput from '@/components/renderer/form/FormInput';
import Icon from '@/components/ui/Icon';
import IfPlan from '@/components/util/IfPlan';
import Popover from '@/components/ui/Popover';
import ErrorBanner from '@/components/ui/ErrorBanner';

import { getAssetsFromValues } from '@/util/AssetsHelper';
import RequestUtils from '@/components/util/RequestUtils';
import SortableColumn from '@/components/renderer/table/SortableColumn';
import SubmitButton from '@/components/renderer/shared/SubmitButton';
import TableCellNoEdit from '@/components/renderer/table/TableCellNoEdit';
import ViewLink from '@/components/shared/ViewLink';
import ViewChildLink from '@/components/renderer/shared/ViewChildLink';

import {
  getDisplayValue,
  getFieldValueRaw,
  getIcon,
  getLinkPageKey,
  getPassedRules,
  getStyle,
  isLinkingToChildPage,
  isRenderSpecialValue,
  isShowingConnectedValues,
  tableCellIsEditable,
} from '@/components/renderer/table/TableCellUtils';

// Scoped "global" variable to track the current group value when rendering records.
// This cannot be a reactive property because mutating it during renders causes an infinite loop.
let currentGroupValue = {};

export default {
  components: {
    ColumnTotals,
    DraggableColumn,
    EmptyFieldsDropZone,
    FieldValueWrapper,
    FormWrapper,
    FormInput,
    Icon,
    IfPlan,
    Popover,
    SortableColumn,
    SubmitButton,
    TableCellNoEdit,
    ViewLink,
    ViewChildLink,
    ErrorBanner,
  },
  mixins: [
    DragDropUtils,
    RequestUtils,
  ],
  props: {

    // TODO: completely remove all view references
    view: {
      type: Object,
      default: () => ({}),
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    showInlineEdit: {
      type: Boolean,
      default: true,
    },
    showCheckboxes: {
      type: Boolean,
      default: false,
    },
    checkedRecordIds: {
      type: Array,
      default: () => [],
    },
    showBuilderLinks: {
      type: Boolean,
      default: false,
    },
    allowSorting: {
      type: Boolean,
      default: true,
    },
    renderTableTotals: {
      type: Boolean,
      default: true,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'builder',
    },
    records: {
      type: Array,
      default: () => [],
    },
    sort: {
      type: Object,
      default: () => ({}),
    },
    routePrefix: {
      type: String,
      default: '',
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    shouldHighlightCell: {
      type: Function,
      default: () => false,
    },
    forceTruncate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['activateColumn', 'clickFieldAction', 'deleteColumn', 'sort', 'toggleCheckboxes'],
  data() {
    return {
      // all used to handle adding a new column that links to an existing page
      errorMessage: '',
      selectLinkedPage: false,
      linkedPage: null,
      newItem: null,
      itemIndex: 0,
      viewDropArea: 'table', // used to define eligible drop areas for adding items (as opposed to search form inputs)
      checkboxAllRecords: false,
      haveShownGroups: {}, // tracks if a groupIndex has been rendered or not
      inlineEditingRecord: null,
      inlineEditingField: null,
      inlineEditingColumn: null,
      inlineEditingLocalValue: null,
      inlineEditingTarget: null,
      inlineEditTeleportDestination: null,
      tableCellIsEditable,
      isInlineInputLoaded: false,
      isRequestPending: false,
    };
  },
  computed: {
    ...mapGetters([
      'getFieldByObject',
      'getField',
    ]),
    passedRules() {
      const passedRules = [];

      this.records.forEach((record, recordIndex) => {
        passedRules[recordIndex] = [];
        this.columns.forEach((column, columnIndex) => {
          passedRules[recordIndex][columnIndex] = getPassedRules({
            column,
            record,
          });
        });
      });

      return passedRules;
    },

    submitIsDisabled() {
      if (this.isRequestPending) {
        return true;
      }

      if (get(this, 'inlineEditingField.type') !== 'connection') {
        return false;
      }

      return !this.isInlineInputLoaded;
    },

    columnsCount() {
      let columnsCount = this.columns.length;

      if (this.showCheckboxes) {
        columnsCount += 1;
      }

      if (this.showBuilderLinks) {
        columnsCount += 1;
      }

      return columnsCount;
    },

    // send these to TableCells so they don't need to look up every field
    // Note: field NOT added as a column property to avoid reactive changes
    // or submitting those to the server
    columnsWithFields() {
      return this.columns.map((column) => {
        let field = null;

        if (!column.field) {
          return {
            column,
            field,
          };
        }

        // if field has already been generated by key (like in the case of babu columns)
        if (column.field && column.field instanceof Field) {
          return {
            column,
            field,
          };
        }

        // Action Links only need a field with a key property
        if (column.type === 'action_link') {
          return {
            column,
            field,
          };
        }

        if (column.objectKey === 'object_preview') {
          // The preview objects will never exist in the store, so just return null here.
          field = null;
        } else if (column.objectKey) {
          field = this.getFieldByObject(column.objectKey, column.field.key);
        } else {
          field = this.getField(column.field.key);
        }

        return {
          column,
          field,
        };
      });
    },

    customTableDesign() {
      if (this.view?.attributes.table_design_active && this.view?.attributes.table_design) {
        return this.view?.attributes.table_design;
      }

      return undefined;
    },

    tableClass() {
      let classes = [
        'knTable',
        'is-fullwidth',
      ];

      if (this.draggable) {
        classes.push('is-disabled');
      }

      // Get classes from design settings if this is a view
      if (this.view?.attributes) {
        classes = [
          ...classes,
          ...getClassesFromDesignSettings(
            designSettingsAliases.TABLE,
            this.$store.getters.app.design,
            this.customTableDesign,
          ),
        ];
      } else {
        // default to striped
        classes.push('knTable--striped');
      }

      return classes;
    },

    // this is a temp replacement for Knack.js current hash
    sceneHash() {
      return '';
    },

    shouldRenderTotals() {
      if (!this.view.get) {
        return false;
      }

      return !isEmpty(this.view.get('totals'));
    },
    rowGroupings() {
      const groupings = [];

      this.columns.forEach((column, index) => {
        if (column.grouping === true) {
          groupings.push({
            column,
            columnIndex: index,
          });
        }
      });

      return groupings;
    },
    groupColSpan() {
      return this.columns.length - this.rowGroupings.length;
    },
    totals() {
      if (isNil(this.view.pageKey)) {
        return [];
      }

      return this.view.get('totals');
    },

    columnsAreEmpty() {
      return this.columns.length === 0;
    },

    isDraggingColumn() {
      return this.$store.getters.isDraggingViewItem(this.view.key);
    },

    addTableColumnPath() {
      return `/pages/${this.$route.params.pageKey}/views/${this.view.key}/table/columns`;
    },
  },
  watch: {
    checkedRecordIds(newIDs) {
      this.checkboxAllRecords = (newIDs.length === this.records.length);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.draggable) {
        this.initDragDrop();
      }
    });
  },
  methods: {
    isLinkingToChildPage(columnAndField) {
      return isLinkingToChildPage(columnAndField);
    },
    getLinkPageKey(columnAndField) {
      return getLinkPageKey(columnAndField.column.scene);
    },
    getCellClasses(recordIndex, columnIndex) {
      const cellClasses = [];

      if (this.shouldHighlightCell(this, recordIndex, columnIndex)) {
        cellClasses.push('cell-highlight');
      }

      return cellClasses;
    },
    recordFieldValueIsShowingConnectedValues({ column }) {
      return isShowingConnectedValues({ column });
    },
    recordFieldValueIsSpecial({ column, field }) {
      return isRenderSpecialValue({ column, field });
    },
    getRecordFieldDisplayValue(column, field, record) {
      return getDisplayValue({
        column,
        record,
        field,
        ignoreLink: true,
      });
    },
    getRecordFieldRawValue(column, field, record) {
      return getFieldValueRaw({ column, record, field });
    },
    getViewLinkText(column) {
      if (column.type === 'action_link') {
        return column.action_rules[0]?.link_text || '';
      }
      return column.link_text;
    },
    getCellIcon(column, passedRules) {
      return getIcon({ column, passedRules });
    },
    getCellStyle(column, passedRules) {
      return getStyle({ column, passedRules });
    },
    getViewLinkStyle(column, passedRules) {
      const cellStyles = getStyle({ column, passedRules });
      if (cellStyles.color) {
        return {
          color: cellStyles.color,
        };
      }
      return {};
    },
    columnIsLinkType(columnType) {
      return ['link', 'action_link', 'delete'].includes(columnType);
    },
    getColumnLinkType(columnType) {
      if (columnType === 'action_link') {
        return 'action';
      }
      if (columnType === 'link') {
        return 'page'; // the default are links to child pages
      }
      return columnType;
    },
    setSort(value) {
      this.$emit('sort', value);
    },
    onDeleteColumn(index) {
      this.$emit('deleteColumn', index);
    },
    onActivateColumn(index) {
      this.$emit('activateColumn', index);
    },
    onClickFieldAction(event, eventVars) {
      this.$emit('clickFieldAction', event, eventVars);
    },
    onToggleAllCheckboxes(event) {
      const toggled = event.target.checked;

      this.$el.querySelectorAll('tr > td:first-child input').forEach(($input) => {
        $input.checked = toggled;
      });

      this.emitToggleCheckboxes();
    },
    onToggleCheckbox(event) {
      this.emitToggleCheckboxes();
    },
    emitToggleCheckboxes() {
      const ids = [];

      this.$el.querySelectorAll('tr > td:first-child input').forEach(($input) => {
        if ($input.checked) {
          ids.push($input.getAttribute('data-id'));
        }
      });

      this.$emit('toggleCheckboxes', ids);
    },
    onClickExpandRecordHistory(recordID) {
      eventBus.$emit('expandRecordHistory', recordID);
    },
    onClickExpandRecord(recordID) {
      eventBus.$emit('expandRecord', recordID);
    },
    onSelectLinkedPage(linkedPage) {
      this.selectLinkedPage = false;

      // add item
      const { newItem } = this;

      newItem.scene = linkedPage;
      newItem.header = linkedPage.name;
      newItem.link_text = linkedPage.name;

      // this.addItem(newItem, this.itemIndex)
      // return this.resetDragDrop()
    },
    isGrouping(column) {
      return column.grouping === true;
    },
    showGroup(record, groupedColumn, groupedColumnIndex, isLastRecord) {
      if (isNil(groupedColumn.field) || record[groupedColumn.field.key] === currentGroupValue[groupedColumnIndex]) {
        return false;
      }

      if (isLastRecord) {
        currentGroupValue = {};
      } else {
        currentGroupValue[groupedColumnIndex] = record[groupedColumn.field.key];
      }

      return true;
    },
    groupLevelClass(groupIndex) {
      return [
        `kn-group-level-${groupIndex + 1}`,
      ];
    },
    showEditGroup(groupIndex) {
      if (this.haveShownGroups[groupIndex]) {
        return false;
      }

      this.haveShownGroups[groupIndex] = true;

      return true;
    },
    ungroupColumn(columnIndex) {
      this.columns[columnIndex].grouping = false;
    },
    sanitizedText(text) {
      // For parity: in v2 renderer, group headers are sanitized using their container's text()
      return $(`<span>${text}</span>`).text();
    },

    // Inline Editing

    getTableCellId(rowNum, colNum) {
      return `tableCell-${rowNum}-${colNum}`;
    },
    async showEditPopover(event, record, field, column) {
      if (!this.showInlineEdit || !tableCellIsEditable({ column, field })) {
        return;
      }

      this.inlineEditingTarget = event.currentTarget;
      this.inlineEditingRecord = record;
      this.inlineEditingField = field;
      this.inlineEditingColumn = column;

      const objectKey = get(column, 'field.objectKey') || get(field, 'objectKey');
      const Record = new RecordModel(record, objectKey);
      this.inlineEditingLocalValue = Record.getValueForInput(field.key);

      this.inlineEditTeleportDestination = `#${this.inlineEditingTarget.id}`;
    },

    onHidePopover() {
      this.errors = [];
      this.errorMessage = '';
      this.isInlineInputLoaded = false;
      this.inlineEditTeleportDestination = null;
    },

    onSubmitInlineEdit() {
      if (this.submitIsDisabled) return;

      const value = this.inlineEditingLocalValue;
      const fieldType = this.inlineEditingField.type;

      if ((fieldType === 'number' || fieldType === 'currency') && value !== '') {
        const regex = /^[0-9. +,-:]+$/;
        if (!regex.test(value)) {
          this.errorMessage = 'The value must be numeric';
          return;
        }
      }

      if (fieldType === 'image' && value?.asset?.content?.type === 'image/svg+xml') {
        this.errorMessage = '.svg files are not supported';
        return;
      }

      const updateRecordValues = {
        [this.inlineEditingField.key]: this.inlineEditingLocalValue,
      };

      const assets = getAssetsFromValues([this.inlineEditingField.getAsFormInput()], updateRecordValues);

      this.isRequestPending = true;

      this.commitRequest({
        request: () => window.Knack.Api.updateRecord(this.inlineEditingField.objectKey, this.inlineEditingRecord.id, updateRecordValues, assets),
        globalLoading: false,
        forceExecuteOnErrorCallback: true,
        onSuccess: (record) => {
          eventBus.$emit('recordUpdate', record);
          this.isRequestPending = false;
          this.closeInlineEdit();
        },
        onError: () => {
          this.isRequestPending = false;
        },
      });
    },

    closeInlineEdit() {
      this.errors = [];
      this.errorMessage = '';
      this.$refs.popover?.hide();
      this.isInlineInputLoaded = false;
      this.inlineEditTeleportDestination = null;
    },

    onInlineInputLoaded() {
      this.isInlineInputLoaded = true;
    },

    onInlineInputUpdated(newValue) {
      this.inlineEditingLocalValue = newValue;
      this.handleClearErrorMessage();
    },

    handleClearErrorMessage() {
      if (!this.errorMessage) return;

      const value = this.inlineEditingLocalValue;
      const fieldType = this.inlineEditingField.type;

      if ((fieldType === 'number' || fieldType === 'currency')) {
        const regex = /^[0-9. +,-:]*$/;
        if (regex.test(value)) {
          this.errorMessage = '';
        }
      }

      if (fieldType === 'image' && !value?.value) {
        this.errorMessage = '';
      }
    },
  },
};
</script>

<style lang="scss">
.kn-table-element > table {

  width: 100%;

  thead .kn-item {
    position: absolute;
    top: -1px; right: -1px; bottom: -1px; left: -1px;
    border: 1px solid transparent;
    border-bottom-color: #ccc;
    padding: 6px 8px;
    cursor: move;
    margin: 0;
    border-radius: 0;
  }

  td .expand-record-link {
    transform: scale(.8);
    display: flex;
    svg {
      color: $fuchsia;
      opacity: .8;
    }
    &:hover svg {
      color: $fuchsia;
      opacity: 1;
    }
  }

  input {
    margin: 0;
    vertical-align: middle;
  }

  .kn-sort svg {
    width: 16px;
    height: 16px;
    color: $fuchsia;
  }

  &.cell-editable {
    td.cell-edit {
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    &.is-link {
      padding: 0;
      & > a {
        padding: 5px 10px;
      }
    }
  }

  &.expand-records {
    td.kn-pivot-calc {
      cursor: pointer;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  &.is-disabled {
    td {
      pointer-events: none;
    }
  }

  .kn-table-group {
    background-color: rgba(0, 0, 0, 0.2) !important;
    td {
      font-weight: bold;

      .kn-item {
        position: absolute;
        top: -1px; right: -1px; bottom: -1px; left: -1px;
        border: 1px solid transparent;
        border-bottom-color: #dbdbdb;
        padding: 6px 8px;
        cursor: move;
        margin: 0;
        border-radius: 0;
      }
    }
  }

  .kn-table-table {
    // Modifiers
    &.can-overflow-x {
      overflow-x: auto;
    }
  }
}

.kn-table-element > table {
  .add-column {
    position: absolute;
    border: 0;
    top: 0;
    right: -40px;
    padding: 5px;
    background-color: rgba(119, 27, 102, .95);
    border-radius: 4px;
    color: #fff;
    height: 25px;
    width: 25px;
    opacity: 0;
  }

  > th:hover > .item-links,
  tr > .item-links,
  > th.router-link-active > .item-links,
  .kn-table > table:hover .add-column,
  .kn-table.is-active > table th > a {
    opacity: 1;
    visibility: visible;
  }

  .item-wrapper:hover .kn-item, .item-wrapper.router-link-active .kn-item {
    border: 1px solid #bbb;
    border-bottom-width: 2px;
    z-index: 3;
  }
  .item-wrapper.router-link-active .kn-item {
    border-color: $active;
  }

  &.is-bordered {
      .item-wrapper:hover .kn-item, th.router-link-active .kn-item {
      border-bottom-width: 1px;
    }
  }
}

.kn-table.is-active th > .item-links {
  background-color: rgba(42, 178, 123, .85);
}

.kn-table {
  .item-wrapper.is-dragging .kn-item {
    border: 1px solid #aaa;
  }

  .v-popper--theme-dropdown .v-popper__arrow {
    display: none;
  }
}

// group indentation
@for $i from 2 through 20 {
  .kn-group-level-#{$i} {
    td {
      padding-left: 20px * ($i - 1) !important;
    }
  }
}

.view .kn-table-group .item-links {
  position: relative;
  top: 0;
  left: 0;
  background: 0;
  margin-left: 4px;
}

.view .kn-table-group:hover .item-links, .view .kn-table-group .item-links.router-link-active {
  visibility: visible;
  opacity: 1;
}

.item-links.router-link-active {
  background-color: #2e8aaf !important;
  border-radius: .34em;

  svg {
    color: #fff !important;
  }
}

td.editable {
  cursor: pointer;

  &:hover {
    background-color: #c7eefe;
  }
}

#kn-table-cell-popover {
  padding: 1em;
  min-width: 300px;
  max-width: 650px;
  font-size: .9em;

  h4 {
    padding: .35rem 1rem;
    font-weight: 500;
    border-bottom: 1px solid #dfdfdf;
  }

  form > div {
    margin-bottom: .75em;
  }

  textarea {
    width: 600px;
    max-width: 100%;
    height: 350px;
  }

  .kn-submit {
    padding: 0;
    margin: 0;

    svg {
      height: 22px;
      width: 22px;
    }
  }

  .kn-button.is-primary {
    background-color: $orange-fill;
    color: #fff;
    font-weight: 500;
  }
}

td.cell-highlight {
  background: #c8eefe;
}

.kn-inlineEditPopover {
  .tooltip-arrow {
    display: none
  }
}

.kn-table-cell {
  .fa-margin-right {
    margin-right: 0.4em;
  }

  .fa-margin-left {
    margin-left: 0.4em;
  }
}

.button-group {
  display: flex;
}
</style>
