<template>
  <div
    v-if="page"
    id="page-views"
    class="kn-content"
    :class="{ 'has-active-view': activeView }"
    style="padding-bottom: 2em;"
  >
    <GlobalLoading
      :is-loading="isLoadingRequest"
      :local="true"
    />
    <div
      id="page-views-bg"
      title="click to return"
      @click="$router.push(`/pages/${$route.params.pageKey}`)"
    />

    <LegacyAppHeader v-if="showHeader" />

    <!-- Dropdown Menu -->
    <template v-if="isMenu">
      <div
        v-if="isNewPage"
        class="body-status"
      >
        <h1>Configure your menu</h1>
        <Icon
          type="empty-point-left"
          class="header-icon"
        />
        <h2>Check each page to include in this dropdown menu.</h2>
      </div>

      <PageDropDownMenuEditor
        v-else-if="page"
        class="body-wrapper"
      />
    </template>

    <template v-if="isLoaded && !isMenu">
      <!-- EMPTY PAGE -->
      <div v-if="page.views.length === 0">
        <!-- NEW PAGE -->
        <div
          id="new-view-drop-zone"
          class="rounded-lg border-2 border-dashed border-pink-600 p-6 bg-gradient-1-white min-h-[200px]"
          :class="{'drop-target': true, 'is-dragging-over': isDraggingFirstView}"
        >
          <Icon
            type="arrow-down"
            class="text-default h-10 w-10 mt-16"
          />
          <h1 class="text-base text-default font-semibold mt-0">
            Drop here
          </h1>
        </div>

        <template v-if="!isDraggingFirstView">
          <template v-if="isNewPage">
            <div
              id="new-page-instructions"
              class="body-status"
            >
              <h2>Set up your new page</h2>
              <Icon
                type="empty-point-left"
                class="header-icon"
              />
              <p class="help">
                <a
                  href="https://learn.knack.com/article/rozrj3rh02-working-with-pages"
                  class="underline fuchsia"
                >
                  Learn more
                </a> about pages.
              </p>
            </div>
          </template>

          <template v-else-if="$route.query.confirmNewPage">
            <div
              id="new-page-confirmation"
              class="body-status"
            >
              <h1>Page added!</h1>
              <h2>Drag views to build your page</h2>
              <Icon
                type="empty-point-left"
                class="header-icon"
              />
              <p class="help">
                <a
                  href="https://learn.knack.com/article/pexqbfnv3l-about-views"
                  class="underline fuchsia link-with-icon link-with-icon--right"
                  target="_blank"
                >
                  Learn more
                  <Icon
                    type="open-in-new"
                    class="link-with-icon__icon"
                  />
                </a> about adding views.
              </p>
            </div>
          </template>

          <template v-else>
            <EmptyStateGeneric
              id="empty-page-instructions"
              top
            >
              <div class="mb-2">
                <Icon
                  type="views"
                  class="h-auto w-[110px] fill-[url(#svg-brand-gradient)] opacity-30"
                />
              </div>

              <h2 class="empty-state__title margin-bottom-lg text-xl text-emphasis font-medium m-0 mb-2">
                Let's add views to this page
              </h2>

              <p class="empty-state__paragraph text-base text-default mb-6">
                Showcase your data with grids, forms, reports and more!
              </p>

              <BuilderButton
                v-if="!$route.query.confirmNewPage"
                theme="confirm"
                size="lg"
                class="mb-6"
                data-cy="add-first-object"
                @click="renderAddViewWizard"
              >
                <icon type="add" /> Add a View
              </BuilderButton>

              <p class="mb-0">
                <a
                  href="https://learn.knack.com/article/pexqbfnv3l-about-views#add_views"
                  target="_blank"
                  class="flex items-center justify-center underline text-default text-base font-medium"
                >
                  Learn more about views
                  <Icon
                    type="open-in-new"
                    class="link-with-icon__icon ml-2 text-emphasis"
                  />
                </a>
              </p>
            </EmptyStateGeneric>
          </template>
        </template>
      </div>

      <!-- PAGE WITH VIEWS -->
      <template
        v-for="(group, groupIndex) in pageGroups"
        v-else
        :key="groupIndex"
      >
        <!-- NEW GROUP SLOT -->
        <div class="kn-container">
          <div
            v-if="group.columns.length > 1 && (groupIndex === 0 || (groupIndex > 0 && pageGroups[groupIndex-1].columns.length > 1))"
            :key="`page-group-${groupIndex}-drop-top`"
            :data-group="groupIndex"
            :data-column="0"
            :data-item="0"
            data-target-type="group-new"
            class="drop-target horizontal"
          >
            <div class="line" />
          </div>
          <div
            v-else-if="groupIndex === 0"
            style="height: 12px;"
          />

          <div
            :key="`page-group-${groupIndex}`"
            data-type="items"
            class="page-group"
          >
            <template
              v-for="(column, columnIndex) in group.columns"
              :key="`${groupIndex}-${columnIndex}`"
            >
              <div
                data-type="items"
                :class="{'is-full-width': group.columns.length === 1 && columnIsFullWidth(column)}"
                class="page-column"
              >
                <!-- drop targets for dragging -->
                <div
                  v-if="group.columns.length < 3"
                  :key="`${column.title}-left-slot`"
                  :data-group="groupIndex"
                  :data-column="columnIndex"
                  :data-item="0"
                  data-target-type="column-new"
                  class="drop-target vertical left"
                >
                  <div class="line" />
                </div>
                <div
                  v-if="group.columns.length < 3 && group.columns.length-1 === columnIndex"
                  :key="`${column.title}-right-slot`"
                  :data-group="groupIndex"
                  :data-column="columnIndex + 1"
                  :data-item="0"
                  data-target-type="column-new"
                  class="drop-target vertical right"
                >
                  <div class="line" />
                </div>

                <template
                  v-for="(view, viewIndex) in column.views"
                  :key="viewIndex"
                >
                  <!-- view component -->
                  <!-- New Views -->
                  <ViewAddPreview
                    v-if="isNewPreview(view)"
                    :view="view"
                  />

                  <ViewWrapper
                    v-else
                    v-slot="{ onViewHeightChange }"
                    :ref="`view-${view.key}`"
                    :view="view"
                    :data-group="groupIndex"
                    :data-column="columnIndex"
                    :data-item="viewIndex"
                    :data-view-key="view.key"
                    :data-feature="`editor_${view.type}_view`"
                    :is-view-only="isNewPage"
                    class="draggable-source"
                    @toggleCollapse="handleToggleCollapse()"
                  >
                    <div
                      class="drop-target top"
                      :data-group="groupIndex"
                      :data-column="columnIndex"
                      :data-item="viewIndex"
                    />

                    <div
                      class="drop-target bottom"
                      :data-group="groupIndex"
                      :data-column="columnIndex"
                      :data-item="viewIndex + 1"
                    />

                    <template v-if="group.columns.length === 1 && group.columns[0].views.length > 1">
                      <div
                        class="drop-target left"
                        :data-group="groupIndex"
                        :data-column="columnIndex"
                        :data-item="viewIndex"
                        data-target-type="column-split"
                      />

                      <div
                        class="drop-target right"
                        :data-group="groupIndex"
                        :data-column="columnIndex"
                        :data-item="viewIndex"
                        data-target-type="column-split"
                      />
                    </template>

                    <Component
                      :is="viewComponent(view.type)"
                      :view="view"
                      @view-height-change="onViewHeightChange"
                    />
                  </ViewWrapper>
                </template>
              </div>
            </template>
          </div>

          <!-- NEW GROUP SLOT -->
          <div
            v-if="groupIndex === pageGroups.length - 1 && pageGroups[groupIndex].columns.length > 1"
            :key="`page-group-${groupIndex}-drop-bottom`"
            :data-group="groupIndex + 1"
            :data-column="0"
            :data-item="0"
            data-target-type="group-new"
            class="drop-target horizontal"
          >
            <div class="line" />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Draggable } from '@shopify/draggable';
import isNil from 'lodash/isNil';
import LegacyAppHeader from '@/components/renderer/header/LegacyAppHeader';
import Calendar from '@/components/renderer/calendar/Calendar';
import Checkout from '@/components/renderer/checkout/Checkout';
import Details from '@/components/renderer/details/Details';
import Divider from '@/components/renderer/form/statics/Divider';
import Icon from '@/components/ui/Icon';
import Form from '@/components/renderer/form/Form';
import GlobalLoading from '@/components/ui/GlobalLoading';
import Image from '@/components/renderer/image/Image';
import List from '@/components/renderer/list/List';
import Login from '@/components/renderer/login/Login';
import MapView from '@/components/renderer/map/Map';
import Menu from '@/components/renderer/menu/Menu';
import PageDropDownMenuEditor from '@/components/pages/PageDropDownMenuEditor';
import PageNavUtils from '@/components/pages/PageNavUtils';
import Report from '@/components/renderer/report/Report';
import RequestUtils from '@/components/util/RequestUtils';
import RichText from '@/components/renderer/richtext/RichText';
import Search from '@/components/renderer/search/Search';
import Table from '@/components/renderer/table/ViewTable';
import ViewSlot from '@/components/pages/ViewSlot';
import ViewCopyUtils from '@/components/views/ViewCopyUtils';
import ViewWrapper from '@/components/views/ViewWrapper';
import ViewAddPreview from '@/components/pages/page/ViewAddPreview';
import BuilderButton from '@/components/ui/BuilderButton';
import EmptyStateGeneric from '@/components/ui/EmptyStateGeneric';
import { getFilledPageGroups, splitGroupColumn } from '@/lib/page/page-groups-helper';

import { eventBus } from '@/store/bus';

export default {
  name: 'PageViews',
  components: {
    Icon,
    LegacyAppHeader,
    ViewWrapper,
    ViewAddPreview,
    BuilderButton,
    calendarView: Calendar,
    detailsView: Details,
    dividerView: Divider,
    formView: Form,
    imageView: Image,
    registrationView: Form,
    listView: List,
    loginView: Login,
    mapView: MapView,
    menuView: Menu,
    rich_textView: RichText,
    searchView: Search,
    tableView: Table,
    reportView: Report,
    checkoutView: Checkout,
    chargeView: Checkout,
    customerView: Checkout,
    ViewSlot,
    PageDropDownMenuEditor,
    GlobalLoading,
    EmptyStateGeneric,
  },
  mixins: [
    RequestUtils,
    PageNavUtils,
    ViewCopyUtils,
  ],
  data() {
    return {
      activeDragSlot: null,
      activeDragView: null,
      isLoaded: false,
      draggable: null,
      hoveringOverPageKeys: [],
      isLoadingRequest: false,
    };
  },
  computed: {
    ...mapGetters([
      'activeView',
      'collapseViews',
      'pagePreviewType',
      'getPageByKey',
      'getPageBySlug',
    ]),

    // Need some flag (figure out what to "flag" based on)
    showHeader() {
      return false;
    },

    allPages() {
      return this.$store.state.pages();
    },

    isAddingView() {
      return this.$route.path.indexOf('views/add') > -1;
    },

    isDraggingFirstView() {
      return this.$store.getters.isDraggingNewView;
    },

    // child view could be a login > registration form or calendar > insert event form
    showChildView() {
      if (this.activeView && this.activeView.isChildView()) {
        return true;
      }

      return false;
    },

    showSlots() {
      return !this.isNewPage;
    },

    pageGroups() {
      // if there's an active view that's also a child view, create a fake group to show this page
      if (this.showChildView) {
        return [
          {
            columns: [
              {
                views: [
                  this.activeView,
                ],
              },
            ],
          },
        ];
      }

      if (!this.page) {
        return [];
      }

      return getFilledPageGroups(this.page, true);
    },
    isMenu() {
      if (isNil(this.page)) {
        return false;
      }

      return this.page.type === 'menu';
    },
    isNewPage() {
      return Boolean(this.page && this.page.isNewPage());
    },

    /**
       * Gets the active page.
       *
       * @returns {Page | null}
       */
    page() {
      return this.$store.getters.activePage;
    },
    pagePreviewId() {
      return this.$store.getters.pagePreviewId;
    },
  },
  watch: {
    isLoaded(isLoaded) {
      if (isLoaded) {
        this.mountDragDrop();
      }
    },
    // this ensurea reset whenever the page is reloaded
    page(newVal, oldVal) {
      // check if page needs to be reset
      if (this.page) {
        // destroy draggable so these can reset for the new page
        if (this.draggable) {
          this.draggable.destroy();
        }

        this.isLoaded = false;

        this.loadPageData();
      }

      // TODO:: clear out stored data on old views? This cold effect memory and performance
    },
    pagePreviewType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadPageData();
      }
    },
  },
  created() {
    // capture event from changing the preview record
    eventBus.$on('changePreviewId', this.loadPageData);

    return this.loadPageData();
  },
  unmounted() {
    if (this.draggable) {
      this.draggable.destroy();
    }
  },
  methods: {
    columnIsFullWidth(column) {
      const tableView = column.views.find((view) => view.type === 'table');

      return tableView !== undefined;
    },
    handleToggleCollapse() {
      // check if we need to set the page ribbon's collapse toggle to match all views being collapsed or expanded
      let collapsedCount = 0;
      let expandedCount = 0;

      for (const view of this.page.views) {
        const isExpanded = this.$refs[`view-${view.key}`]?.[0]?.$el?.classList?.contains('js_isExpanded');

        if (isNil(isExpanded)) {
          // The reference didn't load, so do nothing.
        } else if (isExpanded) {
          expandedCount++;
        } else {
          collapsedCount++;
        }
      }

      // toggle if currently collapsed but all the views are expanded
      if (this.collapseViews && collapsedCount === 0) {
        this.$store.commit('toggleViewCollapse');
      }

      // toggle if expanded but all the views are collapsed
      if (!this.collapseViews && expandedCount === 0) {
        this.$store.commit('toggleViewCollapse');
      }
    },
    isNewPreview(view) {
      if (view.isNew()) {
        if (view.type === 'form' && !view.source.object) {
          return true;
        }

        if (view.type === 'checkout' || view.type === 'charge') {
          return true;
        }

        if (!view.dataIsLoaded()) {
          return true;
        }

        return false;
      }

      return false;
    },

    viewComponent(type) {
      return `${type}View`;
    },
    mountDragDrop() {
      this.draggable = new Draggable([
        document.querySelector('#page-views'),
        document.querySelector('#kn-layout'),
      ], {
        draggable: '.view, .drop-target, .nav-item',
        handle: '.handle',
        classes: {
          'draggable:over': 'over',
          'container:dragging': 'is-dragging-over',
        },
        attachTo: 'body',
        mirror: {
          constrainDimensions: true,
        },
        scrollable: {
          speed: 25,
          sensitivity: 13,
        },
      });

      this.draggable.on('drag:start', (event) => {
        this.activeDragView = event.data.originalSource;
        this.draggingViewKey = event.data.originalSource.getAttribute('data-view-key');
      });

      this.draggable.on('drag:stop', (event) => {
        this.onDrop(event);
      });

      this.draggable.on('drag:over', (event) => {
        this.activeDragSlot = event.over;

        if (this.activeDragSlot.getAttribute('data-key') && !this.hoveringOverPageKeys.includes(this.activeDragSlot.getAttribute('data-key'))) {
          this.hoveringOverPageKeys.push(this.activeDragSlot.getAttribute('data-key'));

          this.getPageByKey(this.activeDragSlot.getAttribute('data-key')).toggle = true;
        }
      });

      this.draggable.on('drag:out', (event) => {
        if (event.over === this.activeDragSlot) {
          return;
        }

        this.activeDragSlot = null;
      });
    },

    resetDragDrop() {
      this.page.groups = this.page.groups.filter((group) => {
        group.columns = group.columns.filter((column) => {
          column.keys = column.keys.filter((key) => key);

          return column.keys.length;
        });

        return group.columns.length;
      });

      this.commitRequest({
        globalLoading: false,
        request: () => this.page.updateLayout(),
        onSuccess: async () => {
          await this.$store.dispatch(
            'sortChildPagesLocally',
            { pageKey: this.page.key },
          );

          await this.$store.dispatch(
            'updatePageLocally',
            {
              pageKey: this.page.key,
              updates: { groups: this.page.groups },
            },
          );
        },
      });
    },

    onDrop(event) {
      this.updateViewPosition(event);
    },

    updateViewPosition(event) {
      const dragItem = this.activeDragView;

      if (!dragItem || !this.activeDragSlot) {
        return;
      }

      const oldGroupIndex = Number(dragItem.getAttribute('data-group'));
      const oldColumnIndex = Number(dragItem.getAttribute('data-column'));
      const oldItemIndex = Number(dragItem.getAttribute('data-item'));

      let groupIndex = Number(this.activeDragSlot.getAttribute('data-group'));
      let columnIndex = Number(this.activeDragSlot.getAttribute('data-column'));
      let itemIndex = Number(this.activeDragSlot.getAttribute('data-item'));

      let dropTargetType = this.activeDragSlot.getAttribute('data-target-type') || 'column-same';

      // dragging down in the same column needs an item index adjustment
      if (oldGroupIndex === groupIndex && oldColumnIndex === columnIndex && oldItemIndex < itemIndex) {
        itemIndex--;
      }

      const view = this.page.groups[oldGroupIndex].columns[oldColumnIndex].keys.splice(oldItemIndex, 1)[0];

      // insert a new group
      // if (this.activeDragSlot.classList.contains(`horizontal`)) {
      if (dropTargetType === 'group-new') {
        // add new group
        this.page.groups.splice(groupIndex, 0, {
          columns: [
            {
              keys: [
                view,
              ],
            },
          ],
        });

        return this.resetDragDrop();
      }

      // split a column
      if (dropTargetType === 'column-split') {
        this.page.groups = splitGroupColumn(this.page.groups, groupIndex, columnIndex, itemIndex);

        // recast drop type to a new column at this split index
        dropTargetType = 'column-new';

        groupIndex++;

        columnIndex = (this.activeDragSlot.classList.contains('left')) ? 0 : 1;
      }

      // dragged into new column slot
      if (dropTargetType === 'column-new') {
        this.page.groups[groupIndex].columns.splice(columnIndex, 0, {
          keys: [
            view,
          ],
        });

        return this.resetDragDrop();
      }

      // dragged to an existing column
      const oldItems = this.page.groups[oldGroupIndex].columns[oldColumnIndex].keys.splice(0);

      // same column
      if (oldGroupIndex === groupIndex && oldColumnIndex === columnIndex) {
        // extract views from column and insert active view
        if (oldItems) {
          oldItems.splice(itemIndex, 0, view);

          this.page.groups[oldGroupIndex].columns[oldColumnIndex].keys = oldItems;
        }

        return this.resetDragDrop();
      }

      // different column
      const newItems = this.page.groups[groupIndex].columns[columnIndex].keys.splice(0);

      newItems.splice(itemIndex, 0, view);
      this.page.groups[groupIndex].columns[columnIndex].keys = newItems;

      // add old views back
      if (oldItems) {
        this.page.groups[oldGroupIndex].columns[oldColumnIndex].keys = oldItems;
      }

      return this.resetDragDrop();
    },

    onDragStart(event) {
      event.stopPropagation();
    },

    onDragOver(event) {
      event.preventDefault();
    },

    loadPageData() {
      if (!this.page) {
        return;
      }

      // sample data can immediately load
      if (this.pagePreviewType !== 'live') {
        this.page.loadViews();

        // Ensure sample data is loaded before rendering the views
        this.$nextTick(() => {
          this.isLoaded = true;
        });
      }

      this.isLoadingRequest = true;

      this.commitRequest({
        globalLoading: false,
        request: () => this.page.loadViews(),
        onSuccess: () => {
          this.isLoaded = true;

          this.isLoadingRequest = false;
        },
      });
    },
    addView(event, location) {
      this.$router.push({
        path: `/pages/${this.page.key}/views/add`,
        query: {
          location: JSON.stringify(location),
        },
      });

      // add class to event
      event.currentTarget.className += ' active';
    },
    renderAddViewWizard() {
      this.$router.push(`/pages/${this.page.key}/views/add`);
    },
  },
};
</script>

<style lang="scss">
#page-views .kn-container {
  margin: 0 20px 7px;
  padding: 0;

  & + .kn-container {
    margin-top: 7px;
  }
}

#page-views {
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  padding-top: 25px;
}

#page-views-bg {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  z-index: 10;
  visibility: hidden;
  background: rgba(8, 8, 9, 0.3);
}

#page-views.has-active-view {

  #page-views-bg {
    visibility: visible;
  }

}

.draggable-mirror {
  opacity: .7;
  background-color: #eaecf0;
  z-index: 111111;

  .blur {
    opacity: .10;
    display: block;
    background-color: #625b77;
  }

  .drop-target {
    display: none;
  }

  .view-edit-links {

  }
}

.view.draggable-source--is-dragging {
  opacity: .75;
}

#page-views > .kn-container > .drop-target.horizontal {
  position: relative;
  height: 12px;
  margin: 0 22px;
}

.page-column {
  flex: 1;
  margin-right: 8px;

  & > .drop-target.vertical {
    position: absolute;
    width: 60px;
    z-index: 6;

    &.left {
    left: -20px;

      .line {
        margin-left: 20px;
      }
    }

    &.right {
      right: -20px;

      .line {
        margin-left: 25px;
      }
    }
  }

  .draggable-source--is-dragging-over {
    opacity: .65;

    .blur {
      opacity: .12;
      display: block;
      background-color: #625b77;
    }
  }
}

.page-column .kn-view > .drop-target {
  visibility: hidden;
  position: absolute;
  z-index: 1115!important;

  .line {
    pointer-events: none; // don't trigger drag events
  }

  &.top {
    top: -4px;
    bottom: 50%;
    border-top: 4px solid rgba(0, 0, 0, 0);
  }

  &.bottom {
    bottom: -2px;
    top: 50%;
    border-bottom: 4px solid rgba(0, 0, 0, 0);
  }

  &.horizontal {
    padding: 4px 0;
    left: 20px;
    right: 20px;
    top: 0;

    .line {
      height: 4px;
      width: 100%;
    }
  }

  &.vertical {
  z-index: 8;
  height: 100%;
  width: 22px;

    .line {
      height: 100%;
      width: 4px;
    }
  }
}
.is-dragging-over .kn-view > .drop-target {
  visibility: visible;
}
.is-dragging-over .kn-view {
  pointer-events: none;

  .drop-target {
    pointer-events: auto;
  }
}

.is-dragging-over .kn-view {

  > .drop-target.top.over {
    border-top: 4px solid black;
  }

  > .drop-target.bottom.over {
    border-bottom: 4px solid black;
  }

  > .drop-target.right.over {
    border-right: 4px solid black;
  }

  > .drop-target.left.over {
    border-left: 4px solid black;
  }
}
.drop-target.horizontal.over .line {
  background-color: black;
}
.drop-target.vertical.over .line {
  background-color: black;
}

.page-group {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  position: relative;

  .page-column {
    position: relative;
  }

  .page-column:last-child .view {
    margin-right: 12px;
  }

  .page-column.is-full-width {

    flex-grow: 1;
  }
}

.slot-wrapper.horizontal {
  position: relative;
  height: 22px;
}

.slot-wrapper.vertical {
  position: absolute;
  padding: 0;
  height: 100%;
  width: 22px;
  z-index: 6;

  &.left {
  left: 0;
  }

  &.right {
  right: 0;
  }
}
.is-dragging-over .slot-wrapper {
  visibility: hidden;
}

.has-active-view {
  .add-view-slot {
    opacity: 0;
    visibility: hidden;
  }
}

.selected-view .add-view-slot {
  opacity: 0;
}

.page-row {
  display: flex;
  position: relative;
  align-items: stretch;
}

label.disabled {
  color: #ccc;
}

#new-view-drop-zone {
  border: 3px dashed #2ab27a;
  color: #2ab27a;
  background-color: #f7fcfa;
  height: 240px;
  text-align: center;
  padding-top: 80px;
  margin: 1em;
  display: none;
  position: relative;

  &.is-dragging-over {
    visibility: visible;
    display: block;
  }

  &.over {
    border-style: solid;
  }

  h1 {
    color: #2ab27a;
  }
}
</style>
