<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { liveAppUrl } from '@/lib/url-helper';
import useLiveAppDesignPreview from '@/composables/ui/useLiveAppDesignPreview';
import IconTextButton from '@/components/ui/IconTextButton';
import ChoicePicker from '@/components/ui/inputs/ChoicePicker';
import FrameListener from '@/lib/frameListener';
import GlobalLoading from '@/components/ui/GlobalLoading';
import { LIVE_APP_PREVIEW_IN_BUILDER } from '@/constants/featureflags';

const {
  startPages, allPages, hasFeature, appName, standardObjects, activePage,
} = useStore().getters;
const route = useRoute();
const router = useRouter();
const showToolbar = ref(true);
const showModal = ref(false);
const frameRef = ref();
const frameLoaded = ref(false);
const returnPageKey = ref();
const { previewSize, setPreviewSize } = useLiveAppDesignPreview();
const schemaData = computed({
  get() {
    return {
      pages: allPages.map((page) => page.name),
      objects: standardObjects.map(({ name }) => name),
      appName,
    };
  },
});
const localPreviewSize = computed({
  get() {
    return previewSize.value;
  },
  set(value) {
    setPreviewSize(value);
  },
});
const containerSize = computed({
  get() {
    switch (previewSize.value) {
      case 'mobile':
        return { width: '390px', height: 'calc(100vh - 86px)', maxHeight: '844px' };
      case 'tablet':
        return { width: '820px', height: 'calc(100vh - 86px)', maxHeight: '1180px' };
      case 'desktop':
      default:
        return { width: '100vw', height: '100vh', maxHeight: '100vh' };
    }
  },
  set(value) {
    setPreviewSize(value);
  },
});

onMounted(() => {
  frameRef.value.onload = () => {
    const frameListener = new FrameListener(frameRef.value);

    frameLoaded.value = true;
    returnPageKey.value = route.params.pageKey;
    frameListener.removeListener('location-change');
    frameListener.addListener('location-change', (viewKey) => {
      returnPageKey.value = viewKey;
    });
  };

  if (route.query.fromAi === 'true') {
    showModal.value = true;
    router.push({ query: {} });
  }
});

function firstPageSlug() {
  /** @type {RawPage[]} pages */
  const firstPage = startPages?.length ? startPages[0] : undefined;

  if (!firstPage) {
    return undefined;
  }

  if (firstPage.isLoginPage()) {
    return firstPage.children[0].slug;
  }

  return firstPage.slug;
}

function getActivePageLiveAppLink() {
  const parentLiveAppLink = liveAppUrl(route.params.pageSlug || firstPageSlug());
  const childLiveAppLink = liveAppUrl(activePage?.parent?.slug || firstPageSlug());

  const isChildPage = activePage?.parent !== null && activePage?.parent !== undefined;

  // eslint-disable-next-line no-underscore-dangle
  const recordId = activePage?._views?.[0]?.data?.records?.[0]?.id;

  if (isChildPage) {
    if (recordId) {
      return `${childLiveAppLink}/${activePage?.attributes?.slug}/${recordId}/`;
    }
    return `${childLiveAppLink}/${activePage?.attributes?.slug}/`;
  }

  return parentLiveAppLink;
}

function exitLiveAppPreview() {
  const newURL = `/pages/${returnPageKey.value}`;

  showToolbar.value = false;
  router.replace({ path: newURL });
}

if (!hasFeature(LIVE_APP_PREVIEW_IN_BUILDER)) {
  exitLiveAppPreview();
}

</script>

<template>
  <div class="body-wrapper fixed inset-0">
    <Transition
      name="exit-preview-topbar"
      appear
    >
      <div
        v-if="showToolbar"
        class="exit-preview-topbar border-0 border-b border-solid border-gray-200"
      >
        <div class="toolbar-left">
          <h4>Live App Preview</h4>
        </div>
        <div class="toolbar-middle">
          <ChoicePicker
            v-model="localPreviewSize"
            borderless
            default-choice="desktop"
            :choices="[
              {
                tooltip: 'Change the preview size to Desktop',
                value: 'desktop',
                faIcon: 'fa-desktop',
              },
              {
                tooltip: 'Change the preview size to Tablet',
                value: 'tablet',
                faIcon: 'fa-tablet',
              },
              {
                tooltip: 'Change the preview size to Mobile',
                value: 'mobile',
                faIcon: 'fa-mobile',
              },
            ]"
          />
        </div>
        <div class="toolbar-right">
          <IconTextButton
            title="Stop Preview"
            variant="brand-50"
            icon="play-stop-arrow"
            label="Stop Preview"
            data-testid="stop-preview-btn"
            test-id="exit-live-app"
            @click="exitLiveAppPreview"
          />
        </div>
      </div>
    </Transition>
    <div class="frame-wrapper">
      <Transition
        name="preview-modal"
        appear
      >
        <div
          v-if="showModal"
          class="modal-position preview-modal"
        >
          <div class="modal" data-testid="page-preview-modal">
            <div class="modal-content">
              <div class="modal-title">
                🎉 Welcome to your "{{ schemaData.appName }}" app in Knack!
              </div>
              <div class="modal-body">
                <p>
                  <!-- eslint-disable-next-line max-len -->
                  We started your app off with {{ schemaData.objects.length }} tables, each with fields and sample data. We also created several pages & buttons so that your users can view all records easily, add / edit records via a form, and view details.
                </p>
                <ul>
                  <h4>Tables</h4>
                  <li
                    v-for="object in schemaData.objects"
                    :key="object"
                  >
                    {{ object }}
                  </li>
                </ul>
                <p class="italic">
                  <!-- eslint-disable-next-line max-len -->
                  Check out your app in the following "Live App" area, then click the "Stop Preview" button to start editing your app in the Builder.
                </p>
                <p class="italic">
                  Enjoy!
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="modal-close"
                @click="() => (showModal = false)"
              >
                See App
              </button>
            </div>
          </div>
        </div>
      </Transition>
      <GlobalLoading
        :is-loading="!frameLoaded && !showModal"
        :style="{position: 'absolute'}"
      />
      <iframe
        ref="frameRef"
        :class="{ frame: true, mobile: previewSize !== 'desktop' }"
        :style="{
          scale: 1,
          backgroundColor: 'white',
          width: containerSize.width,
          height: containerSize.height,
          maxHeight: containerSize.maxHeight,
          'transform-origin': 'top',
        }"
        :src="getActivePageLiveAppLink()"
      />
    </div>
  </div>
</template>

<style>
.frame-wrapper {
  position: relative;
  flex: 1 1 auto;
  width: auto;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #676767;
}

.frame {
  position: relative;
  transition: all 0.2s;
}

.frame.mobile {
  margin: 16px;
  box-shadow: black 1px 1px 2px -3px;
}

.toolbar-left {
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  & svg {
    bottom: -2px;
    padding: 4px;
  }

  & h4 {
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.32px;
  }
}

.toolbar-middle {
  flex: 1 1 auto;
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.toolbar-right {
  width: 33%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
}

.exit-preview-topbar {
  position: relative;
  display: flex;
  height: 56px;
  justify-content: space-between;
  box-shadow: 0px 0px 2px 0px black;
  align-items: center;
  padding: 12px 16px;
  opacity: 1;
  transition: all 0.3s 0.1s;
}

.exit-preview-topbar-enter-active,
.exit-preview-topbar-leave-active {
  position: relative;
  display: flex;
  height: 56px;
  justify-content: space-between;
  box-shadow: 0px 0px 2px 0px black;
  align-items: center;
  padding: 12px 16px;
  opacity: 1;
}

.exit-preview-topbar-enter-from,
.exit-preview-topbar-leave-to {
  opacity: 0;
}

.exit-preview-topbar-enter-to,
.exit-preview-topbar-leave-from {
  opacity: 1;
}

.exit-preview-topbar svg {
  fill: var(--content-brand, #982a86);
}

.preview-layout .body-wrapper {
  margin: 0;
}

.preview-modal {
  opacity: 1;
  transition: all 0.2s;
  z-index: 100;
}

.preview-modal-enter-from,
.preview-modal-leave-to {
  opacity: 0;
}

.preview-modal-enter-active,
.preview-modal-leave-active {
  opacity: 0;
}

.preview-modal-enter-to,
.preview-modal-leave-fram {
  opacity: 1;
  transition: all 0.2s 0.4s;
}

.modal-position {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  background-color: #0000008f;
  & .modal {
    width: 480px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.16);
    border-radius: 12px;
    background-color: white;

    & .modal-content {
      background-color: #fafafa;
      min-height: 228px;
      width: 100%;
      padding: 24px 24px 16px 24px;
      border-radius: 12px 12px 0 0;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      gap: 24px;

      & .modal-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      & .modal-body {
        display: flex;
        flex-direction: column;
        gap: 24px;

        & ul {
          max-height: 150px;
          overflow: auto;
          list-style-type: disc;
          list-style-position: inside;
        }

        & li {
          margin-left: 7px;
        }

        & h4 {
          font-weight: bold;
        }

        & p {
          margin: 0;
        }

        & p.italic {
          font-style: italic;
        }
      }
    }

    & .modal-footer {
      padding: 16px 24px;
      width: 100%;
      height: 72px;
      box-shadow: 0px 6px 16px 0px #0000001A;
      background-color: #fafafa;
      border-radius: 0 0 12px 12px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & .modal-close {
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: none;
        color: white;
        border-radius: 8px;
        background: linear-gradient(90deg, #86307b -18.36%, #e61875 101.49%);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
}
</style>
