<template>
  <div
    id="sidebar"
    class="bg-white p-1 pt-0"
  >
    <nav id="sidebar-nav">
      <ul class="space-y-1">
        <li v-if="false">
          <RouterLink
            to="/home"
            class="active transition"
            data-cy="nav-home"
          >
            <Icon type="home" />
            <span>Home</span>
          </RouterLink>
        </li>
        <li>
          <RouterLink
            :to="schemaLink"
            class="active transition group"
            :class="[
              {
                'hover:bg-brand-50': !isActiveRoute(schemaLink),
                'bg-brand-100 text-emphasis': isActiveRoute(schemaLink),
              },
              navItemClasses,
            ]"
            data-cy="nav-schema"
          >
            <Icon
              class="h-6 w-6"
              :class="{
                'group-hover:text-brand-400': !isActiveRoute(schemaLink),
                'fill-[url(#svg-brand-gradient)]': isActiveRoute(schemaLink),
              }"
              type="table-cells"
            />
            Data
          </RouterLink>
        </li>
        <li>
          <RouterLink
            :to="recordsLink"
            class="transition group"
            :class="[
              {
                'hover:bg-brand-50': !isActiveRoute(recordsLink),
                'bg-brand-100 text-emphasis': isActiveRoute(recordsLink),
              },
              navItemClasses,
            ]"
            data-cy="nav-records"
          >
            <Icon
              class="h-6 w-6"
              :class="{
                'group-hover:text-brand-400': !isActiveRoute(recordsLink),
                'fill-[url(#svg-brand-gradient)]': isActiveRoute(recordsLink),
              }"
              type="database-records"
            />
            Records
          </RouterLink>
        </li>
        <IfPlan :level-is-minimum-of="2">
          <li>
            <RouterLink
              :to="tasksLink"
              class="transition group"
              :class="[
                {
                  'hover:bg-brand-50': !isActiveRoute(tasksLink),
                  'bg-brand-100 text-emphasis': isActiveRoute(tasksLink),
                },
                navItemClasses
              ]"
              data-cy="nav-tasks"
            >
              <Icon
                class="h-5 w-5 mb-1"
                :class="{
                  'group-hover:text-brand-400': !isActiveRoute(tasksLink),
                  'fill-[url(#svg-brand-gradient)]': isActiveRoute(tasksLink),
                }"
                type="action-task-2"
              />
              Tasks
            </RouterLink>
          </li>
        </IfPlan>
        <li>
          <RouterLink
            :to="pagesLink"
            class="transition group"
            :class="[
              {
                'hover:bg-brand-50': !isActiveRoute(pagesLink),
                'bg-brand-100 text-emphasis': isActiveRoute(pagesLink),
              },
              navItemClasses
            ]"
            data-cy="nav-pages"
          >
            <Icon
              class="h-6 w-6"
              :class="{
                'group-hover:text-brand-400': !isActiveRoute(pagesLink),
                'fill-[url(#svg-brand-gradient)]': isActiveRoute(pagesLink),
              }"
              type="pages"
            />
            Pages
          </RouterLink>
        </li>
        <li>
          <RouterLink
            :to="settingsLink"
            class="transition group"
            :class="[
              {
                'hover:bg-brand-50': !isActiveRoute(settingsLink),
                'bg-brand-100 text-emphasis': isActiveRoute(settingsLink),
              },
              navItemClasses
            ]"
            data-cy="nav-settings"
          >
            <Icon
              class="h-6 w-6"
              :class="{
                'group-hover:text-brand-400': !isActiveRoute(settingsLink),
                'fill-[url(#svg-brand-gradient)]': isActiveRoute(settingsLink),
              }"
              type="settings"
            />
            Settings
          </RouterLink>
        </li>
        <li>
          <a
            class="transition px-0 rounded-lg py-2 hover:bg-brand-50 font-xs group"
            :class="navItemClasses"
            data-cy="nav-data-model"
            :href="dataModelUrl"
            @click="handleDataModelLinkClick"
          >
            <Icon
              class="h-6 w-6 group-hover:text-brand-400"
              type="connections"
            />
            Data Model
          </a>
        </li>
        <li
          style="margin-top: auto !important; margin-bottom: 12px !important"
          class="mx-auto"
        >
          <ChecklistsNavButton v-if="hasChecklists" />
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import Icon from '@/components/ui/Icon';
import IfPlan from '@/components/util/IfPlan';
import { logEvent } from '@/lib/segment-helper';
import { getBuilderNextBaseUrl } from '@/lib/url-helper';
import { safeLogRocketTrack } from '@/lib/logrocket-helper';
import ChecklistsNavButton from '@/components/checklists/ChecklistsNavButton.vue';

export default {
  name: 'Sidebar',
  components: {
    ChecklistsNavButton,
    Icon,
    IfPlan,
  },
  computed: {
    ...mapGetters('routes', [
      'getRoute',
    ]),
    ...mapGetters([
      'appSlug',
      'accountSlug',
    ]),
    schemaLink() {
      return this.getRouteLink('schema', 'schema/list');
    },
    recordsLink() {
      return this.getRouteLink('records');
    },
    pagesLink() {
      return this.getRouteLink('pages');
    },
    tasksLink() {
      return this.getRouteLink('tasks');
    },
    settingsLink() {
      return this.getRouteLink('settings');
    },
    dataModelUrl() {
      return `${getBuilderNextBaseUrl()}/data-model`;
    },
    navItemClasses() {
      return 'text-default px-0 rounded-lg py-2 font-xs';
    },
    hasChecklists() {
      return !!this.$store.getters['checklists/data'];
    },
  },
  methods: {
    getRouteLink(routerGetterKey, defaultRoute = routerGetterKey) {
      const persistedRoute = this.getRoute(routerGetterKey);
      const { path: currentRoute } = this.$route;

      if (!isEmpty(persistedRoute) && persistedRoute !== currentRoute) {
        return persistedRoute;
      }

      return `/${defaultRoute}`;
    },
    isActiveRoute(link) {
      const pathSegments = link.split('/');
      const firstSegment = pathSegments[1];

      return this.$route.matched[0].path.includes(firstSegment);
    },
    handleDataModelLinkClick() {
      safeLogRocketTrack('NavigateToBuilderNext');
      logEvent('Clicked Action', {
        app_id: this.$store.getters.app.id,
        account_id: this.$store.getters.app.account?.id,
        location: 'Sidebar',
        text: 'Data Model',
      });
    },
  },
};
</script>

<style lang="scss">
#sidebar-nav {
  height: 100%;
  margin-bottom: 1em;

  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  a {
    color: $gray200;
    display: flex;
    flex-direction: column;
    padding: 1em;
    text-align: center;
    @include font-left-help-nav-regular;

    span {
      color: $gray200;
    }

    &.router-link-active {
      background-color: $gray900;
      color: $white100;
    }

    &:not(.router-link-active):hover {
      color: $gray200;
      background-color: $gray800;
    }

    svg {
      width: 22px;
      height: 22px;
      margin: auto;
      margin-bottom: 2px;

      &.icon-home {
        margin-top: 3px;
        margin-bottom: -1px;
      }

      &.icon-page {
        width: 27px;
        height: 27px;
        margin-top: 1px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
