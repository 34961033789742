<script setup>

import { computed } from 'vue';
import Icon from '@/components/ui/Icon.vue';

const { first_name: firstName, avatar_uri: avatarUri } = window.Knack.Api;

const dashboardUrl = computed(() => process.env.VUE_APP_DASHBOARD_URL);
</script>

<template>
  <div class="flex items-center gap-3">
    <a
      :href="`${dashboardUrl}/settings/account`"
      class="hover:bg-transparent text-default p-0"
    >
      <img
        v-if="avatarUri"
        :src="avatarUri"
        class="w-8 h-8 rounded-full"
        alt="Profile Avatar"
      >
      <Icon
        v-else
        type="user-circle"
        class="w-8 h-8"
      />
    </a>

    <div class="flex flex-col">
      <div class="text-sm font-medium">
        {{ firstName ? `${firstName}'s` : 'Your' }} Onboarding Guide
      </div>
      <div class="text-xs">
        Complete these tasks in any order to get started
      </div>
    </div>
  </div>
</template>
