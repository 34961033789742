/**
 * Maps to FlagSmith ID
 * @const {string}
 */
// eslint-disable-next-line import/prefer-default-export
export const ENCODED_RECORD_IDS = 'encoded_record_ids';
export const INTEGRATIONS = 'integrations';
export const LIVE_APP_PREVIEW_IN_BUILDER = 'live_app_preview_in_builder';
export const DATA_TABLE_V1 = 'data_table_v1';
export const ADD_IMPORT_V1 = 'add_import_v1';
